export function getMembers(state) {
    return state.MemberReducer.members;
}

export function getMemberReferals(state) {
    return state.MemberReducer.referals;
}

export function getMemberReferees(state) {
    return state.MemberReducer.referees;
}

export function getWalletInformation(state) {
    return state.MemberReducer.walletInfo;
}