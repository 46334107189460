import { Avatar, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { getTokenValue } from '../../helpers/utility';
import { getAccessToken } from '../../store/authentication/auth.selector';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(2),
        margin: theme.spacing(1, 0),
        color: '#FFFFFF',
        marginBottom: theme.spacing(2)
    },
    listItem: {
        padding: 0
    },
    calendarItem: {
        right: 0
    },
    leftIcon: {
        color: "#FFFFFF"
    },
    avatar: {
        backgroundColor: theme.palette.secondary.dark,
    }
}));

export default function TitleBar(props) {
    const classes = useStyles();

    const { accessToken } = useSelector((state) => {
        return {
            accessToken: getAccessToken(state),
        }
    });

    const user = getTokenValue(accessToken);
    
    return (
        <List className={classes.root}>
            <ListItem className={classes.listItem}>
                <ListItemIcon className={classes.leftIcon}>
                    <Avatar className={classes.avatar}>
                        {props.icon}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={props.heading}
                    secondary={<>{user.firstName} {user.lastName} / {user.userId}</>}
                    classes={{ secondary: classes.leftIcon }}
                />
            </ListItem>
            {
                props.action &&
                <ListItemSecondaryAction>
                    {props.action}
                </ListItemSecondaryAction>
            }
        </List>
    );
}