import { Avatar, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import { AccountBalance, ArrowBackSharp, Close, CloudDownload } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadIllustrationReport } from '../../helpers/PDFGenerator';
import { fetchPlanRequest, fetchPlansRequest, updatePlanRequest } from '../../store/plan/plan.actions';
import { getPlanDetail, getPlans } from '../../store/plan/plan.selector';
import TitleBar from '../base/TitleBar';
import { IsNullOrEmpty } from '../../helpers/utility';
import { tr } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function PlanContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isEdit, setEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [amount, setAmount] = useState(100);
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

    const [planName, setPlanName] = useState("");
    const [withdrawReturns, setWithdrawReturns] = useState("");
    const [monthlyReturns, setMonthlyReturns] = useState("");
    const [reinvestment, setReinvestment] = useState("");
    const [liquidity, setLiquidity] = useState("");
    const [adminFees, setAdminFees] = useState("");
    const [duration, setDuration] = useState("");

    const [isValidated, setValidated] = useState(false);

    const validate = () => {
        if (IsNullOrEmpty(planName)) {
            return false;
        }

        if (IsNullOrEmpty(duration) || duration <= 0) {
            return false;
        }

        if (IsNullOrEmpty(withdrawReturns) || withdrawReturns <= 0) {
            return false;
        }

        if (IsNullOrEmpty(liquidity) || liquidity <= 0) {
            return false;
        }

        if (IsNullOrEmpty(adminFees) || adminFees <= 0) {
            return false;
        }

        if (IsNullOrEmpty(withdrawReturns) || withdrawReturns <= 0) {
            return false;
        }

        if (IsNullOrEmpty(monthlyReturns) || monthlyReturns <= 0) {
            return false;
        }

        if (IsNullOrEmpty(reinvestment) || reinvestment <= 0) {
            return false;
        }
        return true;
    }


    const handleSave = () => {
        if (validate()) {
            dispatch(updatePlanRequest({
                id: selectedPlan,
                planName: planName,
                duration: duration,
                withdrawReturns: withdrawReturns,
                monthlyReturns: withdrawReturns * monthlyReturns / 100,
                reinvestment: withdrawReturns * reinvestment / 100,
                liquidity: withdrawReturns * liquidity / 100,
                adminFees: withdrawReturns * adminFees / 100,
            }))
        }
        else {
            setValidated(true);
        }
    }

    useEffect(() => {
        dispatch(fetchPlansRequest());
    }, [])

    const { plans, planDetail } = useSelector((state) => {
        return {
            plans: getPlans(state),
            planDetail: getPlanDetail(state)
        }
    }, []);

    useEffect(() => {
        setEdit(false);
    }, [plans])

    return (
        <div className='mb-4 pb-4'>
            <Dialog maxWidth="sm" open={isEdit} onClose={() => {
                setEdit(false);
                setSelectedPlan("");
            }}>
                <DialogContent>
                    <DialogTitle>
                        Plan Information
                        <Close className='float-right' onClick={() => {
                            setEdit(false);
                            setSelectedPlan("");
                        }} />
                    </DialogTitle>
                    <TextField
                        variant='outlined'
                        placeholder='Plan Name'
                        label="Plan Name"
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        error={isValidated && IsNullOrEmpty(planName)}
                        helperText={isValidated && IsNullOrEmpty(planName) ? "Plan Name should be valid" : ""}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Duration'
                        label="Duration"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                Months
                            </InputAdornment>
                        }}
                        error={isValidated && IsNullOrEmpty(duration)}
                        helperText={isValidated && (IsNullOrEmpty(duration) || parseFloat(duration) <= 0) ? "Duration should be valid" : ""}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Withdraw Returns'
                        label="Withdraw Returns"
                        value={withdrawReturns}
                        onChange={(e) => setWithdrawReturns(e.target.value)}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                %
                            </InputAdornment>
                        }}
                        error={isValidated && IsNullOrEmpty(withdrawReturns)}
                        helperText={isValidated && (IsNullOrEmpty(withdrawReturns) || parseFloat(withdrawReturns) <= 0) ? "Withdraw Retuns should be valid" : ""}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Monthly Returns'
                        label="Monthly Returns"
                        value={monthlyReturns}
                        onChange={(e) => setMonthlyReturns(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                %
                            </InputAdornment>
                        }}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        error={isValidated && IsNullOrEmpty(monthlyReturns)}
                        helperText={isValidated && (IsNullOrEmpty(monthlyReturns) || parseFloat(monthlyReturns) <= 0) ? "Monthly Retuns should be valid" : ""}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Reinvestment'
                        label="Reinvestment"
                        value={reinvestment}
                        onChange={(e) => setReinvestment(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                %
                            </InputAdornment>
                        }}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        error={isValidated && IsNullOrEmpty(reinvestment)}
                        helperText={isValidated && (IsNullOrEmpty(reinvestment) || parseFloat(reinvestment) <= 0) ? "Reinvestment should be valid" : ""}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Liquidity'
                        label="Liquidity"
                        value={liquidity}
                        onChange={(e) => setLiquidity(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                %
                            </InputAdornment>
                        }}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        error={isValidated && IsNullOrEmpty(liquidity)}
                        helperText={isValidated && (IsNullOrEmpty(liquidity) || parseFloat(liquidity) <= 0) ? "Liquidity should be valid" : ""}
                    />
                    <TextField
                        variant='outlined'
                        placeholder='Admin Fees'
                        label="Admin Fees"
                        value={adminFees}
                        onChange={(e) => setAdminFees(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                %
                            </InputAdornment>
                        }}
                        fullWidth
                        margin='dense'
                        className='px-2'
                        error={isValidated && IsNullOrEmpty(adminFees)}
                        helperText={isValidated && (IsNullOrEmpty(adminFees) || parseFloat(adminFees) <= 0) ? "Admin Fees should be valid" : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => handleSave()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <TitleBar heading="Plans" secondary={""}
                icon={<AccountBalance />}
                action={open ? <Button className='text-white' onClick={() => setOpen(false)} startIcon={<ArrowBackSharp />}>Back</Button> : undefined} />
            <div className={classes.root}>
                {
                    open ? <>
                        <Grid container spacing={2}>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <TextField
                                    onChange={(e) => {
                                        setAmount(e.target.value);
                                        dispatch(fetchPlanRequest({
                                            id: selectedPlan,
                                            amount: e.target.value,
                                            date: date
                                        }))
                                    }}
                                    fullWidth
                                    variant='outlined'
                                    value={amount}
                                    margin='dense'
                                    label="Amount"
                                />
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <TextField
                                    onChange={(e) => {
                                        setDate(e.target.value);
                                        dispatch(fetchPlanRequest({
                                            id: selectedPlan,
                                            amount: amount,
                                            date: e.target.value
                                        }))
                                    }}
                                    fullWidth
                                    type='date'
                                    variant='outlined'
                                    defaultValue={moment().format("YYYY-MM-DD")}
                                    value={date}
                                    margin='dense'
                                    label="Date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} lg={4} sm={12} xs={12}>
                                <Button onClick={() => {
                                    downloadIllustrationReport(planDetail)
                                }} variant='contained' color="secondary" className='mt-2' fullWidth startIcon={<CloudDownload />}>Download</Button>
                            </Grid>
                        </Grid>
                        <table className='table table-striped mt-2'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Deposit Amount</th>
                                    <th>Interest Gained</th>
                                    <th>Net Withdraw</th>
                                    <th>Gas Fees</th>
                                    <th>Liquidity</th>
                                    <th>Reinvestment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {planDetail && planDetail.map((s, index) => {
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>{s.date}</td>
                                        <td>{s.amount} USD</td>
                                        <td>{s.interest} USD</td>
                                        <td>{s.withdrawalAmount} USD</td>
                                        <td>{s.adminFees} USD</td>
                                        <td>{s.liquidityFees} USD</td>
                                        <td>{s.reinvest} USD</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </>
                        :
                        <Grid container spacing={2}>
                            {
                                plans.map((s, index) => {
                                    return <Grid item md={6} lg={6} sm={12} xs={12}>
                                        <Card variant='outlined'>
                                            <CardHeader
                                                avatar={<Avatar style={{ background: deepOrange[500], color: "#FFFFFF" }}>
                                                    {index + 1}
                                                </Avatar>}

                                                title={s.planName}
                                                action={<>
                                                    <Button color="secondary" variant="contained" size="small" onClick={() => {
                                                        setSelectedPlan(s._id);
                                                        setPlanName(s.planName);
                                                        setDuration(s.duration);
                                                        setWithdrawReturns(s.withdrawReturns);
                                                        setMonthlyReturns(Math.ceil(parseFloat(s.monthlyReturns * 100 / s.withdrawReturns)));
                                                        setReinvestment(parseFloat(s.reinvestment * 100 / s.withdrawReturns));
                                                        setLiquidity(parseFloat(s.liquidity * 100 / s.withdrawReturns));
                                                        setAdminFees(parseFloat(s.adminFees * 100 / s.withdrawReturns));
                                                        setEdit(true)

                                                    }} >Edit</Button>
                                                    &nbsp;
                                                    <Button color="primary" variant="contained" size="small" onClick={() => {
                                                        setSelectedPlan(s._id);
                                                        dispatch(fetchPlanRequest({
                                                            id: s._id,
                                                            amount: amount,
                                                            date: date
                                                        }))
                                                        setOpen(true);
                                                    }} >Calculate</Button>
                                                </>
                                                }
                                                subheader={s.duration + " months"} />
                                            <Divider />
                                            <CardContent>
                                                <ul className='stripe-ul'>
                                                    <li>
                                                        <span>Monthly Interest :</span>
                                                        <span>{s.withdrawReturns} %</span>
                                                    </li>
                                                    <li>
                                                        <span>Net Withdraw: </span>
                                                        <span>{Math.ceil(parseFloat(s.monthlyReturns * 100 / s.withdrawReturns))} %</span>
                                                    </li>
                                                    <li>
                                                        <span>Reinvestment : </span>
                                                        <span>{parseFloat(s.reinvestment * 100 / s.withdrawReturns)} %</span>
                                                    </li>
                                                    <li>
                                                        <span>Liquidity : </span>
                                                        <span>{parseFloat(s.liquidity * 100 / s.withdrawReturns)} %</span>
                                                    </li>
                                                    <li>
                                                        <span>Gas Fees : </span>
                                                        <span>{parseFloat(s.adminFees * 100 / s.withdrawReturns)} %</span>
                                                    </li>
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                }
            </div>
        </div >
    );
}

