import HttpHelper from '../../helpers/httpHelper';
import { createWhitelistUrl, get2FASetup, getChangePasswordURI, getForgotPasswordURI, getSendEmailOTP, getSendPhoneOTP, getStatUrl, setSendEmailOTP, setSendPhoneOTP, verify2FAUrl } from "./user.uri";

require('dotenv').config();
const headerInfo = {};

class UserAPI {
    static async RegisterUser(req) {
        let url = process.env.REACT_APP_API_URL + '/user';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req);
    }

    static async GetUsers() {
        let url = process.env.REACT_APP_API_URL + '/users';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetCurrentUser() {
        let url = process.env.REACT_APP_API_URL + '/user';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdateCurrentUser(req) {
        let url = process.env.REACT_APP_API_URL + '/user';
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, req);
    }

    static async SetEmailOTP(req) {
        let url = getSendEmailOTP();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async SetPhoneOTP(req) {
        let url = getSendPhoneOTP();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async VerifyEmailOTP(req) {
        let url = setSendEmailOTP(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async VerifyPhoneOTP(req) {
        let url = setSendPhoneOTP(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Get2FASetUpCode(req) {
        let url = get2FASetup();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Verify2FACode(code) {
        let url = verify2FAUrl();
        return HttpHelper.httpRequest(url, 'PATCH', {}, {
            "code": code
        });
    }

    static async GetStats() {
        let url = getStatUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async ForgotPassword(req) {
        let url = getForgotPasswordURI(req);
        return HttpHelper.httpRequest(url, 'POST', {});
    }

    static async ChangePassword(req) {
        let url = getChangePasswordURI();
        return HttpHelper.httpRequest(url, 'PATCH', {}, req);
    }
}

export default UserAPI;
