import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, LinearProgress, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, TextField, makeStyles } from '@material-ui/core';
import { AccountBalance, CalendarToday, Close, Code, Email, FileCopy, Fingerprint, FlipToBackRounded, FormatAlignCenter, Money, OpenInBrowser, OpenInNew, Save, Star, TransferWithinAStation, Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from "toastr";
import _ from "underscore";
import { fetchPlanMemberRequest, updatePlanMemberRequest } from '../../store/transaction/transaction.actions';
import { getPlanMemberRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';
import { updateMemberRequest } from '../../store/member/member.actions';
import Loader from '../base/loader';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function PlanMemberContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);
    const [amount, setAmount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(null);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        dispatch(fetchPlanMemberRequest({
            page: 0,
            pageSize: 10
        }))
    }, [])

    const { planMembers, isLoading, totalPlanMembers } = useSelector((state) => {
        return {
            planMembers: getPlanMemberRequests(state),
            isLoading: state.TransactionReducer.isLoading,
            totalPlanMembers: state.TransactionReducer.totalPlanMembers,
        }
    }, []);

    useEffect(() => {
        setAmount(0);
    }, [planMembers])

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(planMembers, x => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        });
    };


    const columns = [
        {
            name: 'memberId',
            label: 'Member',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'createdAt',
            label: 'Joined Time',
            options: {
                filter: true,
                sort: true,
            }
        },
        // {
        //     name: 'maturityDate',
        //     label: 'Maturity Date',
        //     options: {
        //         filter: true,
        //         sort: true,
        //     }
        // },
        {
            name: 'token',
            label: 'Tokens',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'amount',
            label: 'Amount',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'accumulatedAmount',
            label: 'Acc Amount',
            options: {
                filter: false,
                sort: true,
            }
        },
        // {
        //     name: 'id',
        //     label: 'Tentative',
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value) => {
        //             let dep = _.find(planMembers, x => x._id === value);
        //             if (moment(dep.createdAt).isBetween("2024-01-01", "2024-02-29")) {
        //                 let updated = parseFloat(Math.round(dep.token * 0.011));
        //                 let diff = dep.amount - updated;
        //                 if (diff < 0) {
        //                     diff = diff * -1;
        //                 }
        //                 return <p style={{ color: diff > 5 ? "red" : "green" }}>{updated} USD</p>
        //             }
        //             if (moment(dep.createdAt).isBetween("2024-03-01", "2024-03-16")) {
        //                 let updated = parseFloat(Math.round(dep.token * 0.014));
        //                 let diff = dep.amount - updated;
        //                 if (diff < 0) {
        //                     diff = diff * -1;
        //                 }
        //                 return <p style={{ color: diff > 5 ? "red" : "green" }}>{updated} USD</p>
        //             }
        //             else {
        //                 let updated = parseFloat(Math.round(dep.token * 0.018));
        //                 let diff = dep.amount - updated;
        //                 if (diff < 0) {
        //                     diff = diff * -1;
        //                 }
        //                 return <p style={{ color: diff > 5 ? "red" : "green" }}>{updated} USD</p>
        //             }
        //         }
        //     }
        // },
        // {
        //     name: 'id',
        //     label: 'id',
        //     options: {
        //         filter: false,
        //         sort: true,
        //         customBodyRender: (value) => {
        //             return <TextField value={amount} onChange={(e) => {
        //                 setAmount(e.target.value);
        //             }}
        //                 variant='outlined'
        //                 margin='dense'
        //                 InputProps={{
        //                     endAdornment: <InputAdornment position='end'>
        //                         <Save onClick={() => {
        //                             dispatch(updatePlanMemberRequest({
        //                                 id: value,
        //                                 amount: amount
        //                             }))
        //                         }} />
        //                     </InputAdornment>
        //                 }}
        //             />
        //         }
        //     }
        // },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'payouts',
            label: 'Payouts',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value ? _.find(value, s => s.type === "1 Level Income") ? "Yes" : "No" : 0
                }
            }
        },
        // {
        //     name: 'id',
        //     label: 'Tentative',
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value) => {
        //             let dep = _.find(planMembers, x => x._id === value);
        //             if (moment(dep.createdAt).isBetween("2024-01-01", "2024-02-29")) {
        //                 return Math.round(dep.token * 0.011) + " USD"
        //             }
        //             if (moment(dep.createdAt).isBetween("2024-03-01", "2024-03-16")) {
        //                 return Math.round(dep.token * 0.014) + " USD"
        //             }
        //             else {
        //                 return Math.round(dep.token * 0.018) + " USD"
        //             }
        //         }
        //     }
        // },
        {
            name: 'contracts',
            label: 'Hash',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return value ? <a href={"https://bscscan.com/tx/" + value[0].transactionHash} target='_blank'> <OpenInNew /> </a> : <></>
                }
            }
        },
        actionRenderer()
    ];

    const datum = _.sortBy(planMembers, t => moment(t.createdAt)).reverse().map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            memberId: item.memberId,
            maturityDate: moment(item.maturityDate).format("lll"),
            token: item.token + " Tokens",
            amount: item.amount + " USD",
            status: item.status,
            payouts: item.payouts,
            contracts: item.contract,
            accumulatedAmount: item.accumulatedAmount
        };
    });

    const options = {
        selectableRows: false,
        rowsPerPage: rowsPerPage,
        serverSide: true,
        filter: false,
        count: totalPlanMembers,
        onChangeRowsPerPage: (numberOfRows) => {
            console.log(numberOfRows, "num");
            setRowsPerPage(numberOfRows);
            dispatch(fetchPlanMemberRequest({
                page: page,
                pageSize: numberOfRows
            }));
        },
        onSearchClose: () => {
            setSearchText("");
            dispatch(fetchPlanMemberRequest({
                page: page,
                pageSize: rowsPerPage,
                search: ""
            }));
        },
        onTableChange: (action, tableState) => {
            console.log(tableState.page, "table state");
            if (action === "changePage") {
                setPage(tableState.page);
                dispatch(fetchPlanMemberRequest({
                    page: tableState.page,
                    pageSize: rowsPerPage,
                    search: searchText
                }));
            } else if (action === "search") {
                setSearchText(tableState.searchText);
                if (tableState.searchText && (tableState.searchText.length > 2 || tableState.searchText.length === 0)) {
                    dispatch(fetchPlanMemberRequest({
                        page: page,
                        pageSize: rowsPerPage,
                        search: tableState.searchText
                    }));
                }
            } else {
                console.log(tableState);
            }
        }
    };

    const selectedPlan = selectedItem;
    let currency = "USD";
    let duration = 0;
    let completed = 0;
    if (selectedPlan) {
        let duration = moment(selectedItem.maturityDate).diff(moment(selectedItem.createdAt), "day");
        let completed = selectedItem.status === "Approved" ? moment().diff(moment(selectedItem.createdAt), "day") : duration
        completed = parseInt(completed * 100 / duration);
    }

    return (
        <div className='mb-4 pb-4'>
            {
                isLoading && <Loader />
            }
            <Dialog maxWidth="md" fullWidth open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>
                    Plan Transaction <Close className='mt-1 float-right' onClick={() => setOpen(false)} />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {
                        selectedPlan &&
                        <>
                            <ul className='stripe-ul'>
                                <li>
                                    <span>Reference No :</span>
                                    <span>{selectedPlan._id}</span>
                                </li>
                                <li>
                                    <span>Joined Date :</span>
                                    <span>{moment(selectedPlan.createdAt).format("lll")}</span>
                                </li>
                                <li>
                                    <span>{selectedPlan.status === "Approved" ? "Maturity Date" : "Unstack Date"} :</span>
                                    <span>{moment(selectedPlan.maturityDate).format("ll")}</span>
                                </li>
                                <li>
                                    <span>Duration : </span>
                                    <span>{selectedPlan.returns && selectedPlan.returns.length} Months</span>
                                </li>
                                <li>
                                    <span>Amount : </span>
                                    <span>{selectedPlan.amount} USD</span>
                                </li>
                            </ul>
                            <LinearProgress className='mt-2' color="primary" style={{ height: 20 }} value={completed} variant="determinate" />

                            <table className='table table-striped mt-2'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Deposit Amount</th>
                                        <th>Reward Gained</th>
                                        <th>Reward Withdrawable</th>
                                        <th>Reinvest Amount</th>
                                        <th>Withdraw Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPlan.returns && selectedPlan.returns.map((s, index) => {
                                        return <tr>
                                            <td>{index + 1}</td>
                                            <td>{s.date}</td>
                                            <td>{s.amount} {currency}</td>
                                            <td>{s.interest} {currency}</td>
                                            <td>{s.withdrawalAmount} {currency}</td>
                                            <td>{s.reinvest} {currency}</td>
                                            <td>
                                                {s.status}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </>
                    }
                    <Divider />
                    <h4>Payouts</h4>
                    {
                        selectedPlan && selectedPlan.payouts &&
                        <table className='table table-striped mt-2'>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Reward</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedPlan.payouts && selectedPlan.payouts.map((s, index) => {
                                    return <tr>
                                        <td>{index + 1}</td>
                                        <td>{s.type}</td>
                                        <td>{moment(s.createdAt).format("LLL")}</td>
                                        <td>{s.amount} {currency}</td>
                                        <td>{s.createdBy}</td>
                                        <td>{s.memberId}</td>
                                        <td>
                                            {s.status}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    }
                </DialogContent>
                {
                    selectedPlan &&
                    <DialogActions>
                        <a href={"https://bscscan.com/tx/" + selectedPlan.transactionHash} target='_blank'> <OpenInNew /> Transaction Hash</a>
                    </DialogActions>
                }
            </Dialog>
            <TitleBar heading="PlanMember Transactions" secondary={""}
                icon={<AccountBalance />} />
            <div className={classes.root}>
                <MUIDataTable
                    title={"PlanMember Transactions List"}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div >
    );
}

