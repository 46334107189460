import { call, put, takeEvery } from 'redux-saga/effects';
import * as PlanActions from './plan.actions';
import PlanAPI from './plan.api';
import { PlanActionTypes } from './plan.model';
import Swal from 'sweetalert2';

export function* handlePlans() {
  try {
    const response = yield call(PlanAPI.GetPlans);
    yield put(PlanActions.fetchPlansSuccess(response));
  } catch (e) {
    yield put(PlanActions.fetchPlansFailure(e));
  }
}

export function* handleDetail(action) {
  try {
    const response = yield call(PlanAPI.GetPlanDetail, action.payload);
    yield put(PlanActions.fetchPlanSuccess(response));
  } catch (e) {
    yield put(PlanActions.fetchPlanFailure(e));
  }
}

export function* handleCreate(action) {
  try {
    const response = yield call(PlanAPI.CreatePlanMember, action.payload);
    yield put(PlanActions.createPlanMemberSuccess(response));
    Swal.fire({
      text: "Joined Plan",
      icon: "success",
      text: "Joined to the plan successfully"
    })
  } catch (e) {
    yield put(PlanActions.createPlanMemberFailure(e));
  }
}

export function* handleUpdatePlan(action) {
  try {
    const response = yield call(PlanAPI.UpdatePlan, action.payload);
    yield put(PlanActions.updatePlanSuccess(response));
    Swal.fire({
      text: "Plan",
      icon: "success",
      text: "Plan Updated Successfully"
    })
  } catch (e) {
    yield put(PlanActions.updatePlanFailure(e));
  }
}

export function* fetchMemberPlans(action) {
  try {
    const response = yield call(PlanAPI.GetPlanMember, action.payload);
    yield put(PlanActions.fetchPlanMemberSuccess(response));
  } catch (e) {
    yield put(PlanActions.fetchPlanMemberFailure(e));
  }
}

export function* PlanSaga() {
  yield takeEvery(PlanActionTypes.FETCH_PLAN_REQUEST, handleDetail);
  yield takeEvery(PlanActionTypes.FETCH_PLANS_REQUEST, handlePlans);
  yield takeEvery(PlanActionTypes.CREATE_PLAN_MEMBER_REQUEST, handleCreate);
  yield takeEvery(PlanActionTypes.UPDATE_PLAN_REQUEST, handleUpdatePlan);
  yield takeEvery(PlanActionTypes.FETCH_PLAN_MEMBER_REQUEST, fetchMemberPlans);
}

