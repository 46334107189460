import { Accordion, AccordionDetails, AccordionSummary, AppBar, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, LinearProgress, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Tab, Tabs } from '@material-ui/core';
import { AccountBalance, CalendarToday, Close, Edit, Deck, AccountBox, FormatAlignCenter, AccountBalanceWalletRounded, Phone, Person, KeyboardHideSharp, ExpandMore, AccountBalanceWallet, Code, Email, FileCopy, Fingerprint, FlipToBackRounded, Money, OpenInNew, Star, TransferWithinAStation, Visibility } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from "toastr";
import _ from "underscore";
import { approveTokenRequest, fetchMemberReferalRequest, fetchMembersRequest, resetMemberRequest, resumeMemberRequest, suspendMemberRequest } from '../../store/member/member.actions';
import { getMembers } from '../../store/member/member.selector';
import { fetchPlanMemberRequest, fetchPlansRequest } from '../../store/plan/plan.actions';
import { getMemberContracts, getMemberPayouts, getMemberSwaps, getMemberWithdraws, getPlanMembers, getPlans } from '../../store/plan/plan.selector';
import TitleBar from '../base/TitleBar';
import Loader from '../base/loader';
import RefereeContainer from './Refer.Container';
import ReferalContainer from './Referal.Container';
import AddMember from './addMember';
import UpdateMember from './editMember';
import { getTransactionUrl } from '../../helpers/utility';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function MemberContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState("Profile");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = React.useState(0);
    const [page, setPage] = useState(null);
    const [searchText, setSearchText] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { members, planMembers, plans, walletInfo, isLoading, payouts, withdraws, contracts, swaps, totalMembers } = useSelector((state) => {
        return {
            members: getMembers(state),
            planMembers: getPlanMembers(state),
            plans: getPlans(state),
            payouts: getMemberPayouts(state),
            withdraws: getMemberWithdraws(state),
            contracts: getMemberContracts(state),
            swaps: getMemberSwaps(state),
            totalMembers: state.MemberReducer.totalMembers,
            isLoading: state.MemberReducer.isLoading,
            walletInfo: state.PlanReducer.walletInfo
        }
    }, []);

    useEffect(() => {
        dispatch(fetchMembersRequest({
            page: 0,
            pageSize: 10
        }));
        dispatch(fetchPlansRequest());
    }, [])

    useEffect(() => {
        setOpen(false);
        setSelecteItem({});
        setMode("");
    }, [members]);

    useEffect(() => {
        if (selectedItem.memberId) {
            dispatch(fetchMemberReferalRequest(selectedItem.memberId));
            dispatch(fetchPlanMemberRequest(selectedItem._id));
        }
    }, [selectedItem])

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(members, x => x._id === value);
                    return (
                        <>


                            <Visibility
                                className={classes.icon}
                                color="secondary"
                                onClick={() => {
                                    setOpen(true);
                                    setSelecteItem(dep);
                                    setMode("Profile");
                                }}
                            />&nbsp;
                            {/* <ListAlt
                                className={classes.icon}
                                color="action"
                                onClick={() => {
                                    setOpen(true);
                                    setSelecteItem(dep);
                                    setMode("Referal");
                                }}
                            />
                            &nbsp; */}
                            {
                                <Edit
                                    className={classes.icon}

                                    color="primary"
                                    onClick={() => {
                                        setSelecteItem(dep);
                                        setMode("edit");
                                    }}
                                />
                            }
                        </>
                    );
                }
            }
        });
    };


    const columns = [{
        name: 'memberId',
        label: 'Member #',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'name',
        label: 'Member Name',
        options: {
            filter: false,
            sort: false,

        }
    },

    {
        name: 'createdAt',
        label: 'Joined On',
        options: {
            filter: false,
            sort: false,
        }
    },

    {
        name: 'planMember',
        label: 'Plans',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                return value.length
            }
        }
    },

    {
        name: 'planMember',
        label: 'Activated On',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                if (value.length === 0) {
                    return "Not Activated";
                }
                else {
                    if (value.length > 1) {
                        let latestDate = _.sortBy(value, t => moment(t.createdAt).unix());
                        latestDate = latestDate[0].createdAt;
                        return moment(latestDate).format("lll")
                    }
                    else {
                        let latestDate = value[0].createdAt;
                        return moment(latestDate).format("lll")
                    }

                }

            }
        }
    },


    {
        name: 'referal',
        label: 'Referral',
        options: {
            filter: false,
            sort: false,
        }
    },
    actionRenderer()
    ];

    const datum = members.map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format("lll"),
            memberId: item.memberId,
            deposit: item.depositAmount || 0,
            refCount: item.refCount,
            phone: item.mobileNumber,
            referal: item.referal,
            email: item.email,
            name: item.firstName + " " + item.lastName,
            planMember: item.planMember,
        };
    });

    const options = {
        selectableRows: false,
        rowsPerPage: rowsPerPage,
        serverSide: true,
        filter: false,
        count: totalMembers,
        onChangeRowsPerPage: (numberOfRows) => {
            console.log(numberOfRows, "num");
            setRowsPerPage(numberOfRows);
            dispatch(fetchMembersRequest({
                page: page,
                pageSize: numberOfRows
            }));
        },
        onSearchClose: () => {
            setSearchText("");
            dispatch(fetchMembersRequest({
                page: page,
                pageSize: rowsPerPage,
                search: ""
            }));
        },
        onTableChange: (action, tableState) => {
            console.log(tableState.page, "table state");
            if (action === "changePage") {
                setPage(tableState.page);
                dispatch(fetchMembersRequest({
                    page: tableState.page,
                    pageSize: rowsPerPage,
                    search: searchText
                }));
            } else if (action === "search") {
                setSearchText(tableState.searchText);
                if (tableState.searchText && (tableState.searchText.length > 2 || tableState.searchText.length === 0)) {
                    dispatch(fetchMembersRequest({
                        page: page,
                        pageSize: rowsPerPage,
                        search: tableState.searchText
                    }));
                }
            } else {
                console.log(tableState);
            }
        }
    };

    return (
        <div className='mb-4 pb-4'>
            {
                isLoading && <Loader />
            }
            <Dialog maxWidth="md" fullWidth open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>
                    Member Information <Close className='mt-1 float-right' onClick={() => setOpen(false)} />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <AppBar color="primary" variant='outlined' position="static">
                        <Tabs value={value} variant="scrollable" onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Profile" {...a11yProps(0)} />
                            <Tab label="Referal" {...a11yProps(1)} />
                            <Tab label="Plans" {...a11yProps(2)} />
                            <Tab label="Withdraws" {...a11yProps(3)} />
                            <Tab label="Buy/Sell" {...a11yProps(4)} />
                            <Tab label="Payouts" {...a11yProps(5)} />
                            <Tab label="Contracts" {...a11yProps(6)} />
                        </Tabs>
                    </AppBar>
                    {
                        value === 0 && <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><Fingerprint /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedItem.memberId} secondary={"Member ID"} />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><AccountBox /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedItem.firstName + " " + selectedItem.lastName} secondary={"Member Name"} />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><Phone /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedItem.mobileNumber} secondary={"Mobile Number"} />
                                    {
                                        selectedItem.isMobileVerified ?
                                            <ListItemSecondaryAction>
                                                <Chip label="Verified" color="secondary" size='small' />
                                            </ListItemSecondaryAction> :
                                            <ListItemSecondaryAction>
                                                <Chip label="Yet to verify" color="default" size='small' />
                                            </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><Email /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedItem.email} secondary={"Email"} />
                                    {
                                        selectedItem.isEmailVerified ?
                                            <ListItemSecondaryAction>
                                                <Chip label="Verified" color="secondary" size='small' />
                                            </ListItemSecondaryAction> :
                                            <ListItemSecondaryAction>
                                                <Chip label="Yet to verify" color="default" size='small' />
                                            </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><CalendarToday /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={moment(selectedItem.createdAt).format("lll")} secondary={"Joined Date"} />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><Star /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedItem.referal} secondary={"Refered By"} />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><AccountBalanceWallet /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primaryTypographyProps={{
                                        className: "overflow-text"
                                    }} primary={selectedItem.walletAddress} secondary={"Wallet Address"} />
                                </ListItem>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className='bg-primary'><AccountBalanceWallet /></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primaryTypographyProps={{
                                        className: "overflow-text"
                                    }} primary={selectedItem.withdrawAddress} secondary={"Withdraw Address"} />
                                </ListItem>
                            </Grid>
                            {
                                selectedItem.nomineeName &&

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className='bg-primary'><Person /></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={selectedItem.nomineeName} secondary={"Nominee Name"} />
                                    </ListItem>
                                </Grid>
                            }
                            {
                                selectedItem.nomineeDOB &&
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className='bg-primary'><CalendarToday /></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={moment(selectedItem.nomineeDOB).format("LL")} secondary={"Nominee DOB"} />
                                        <ListItemSecondaryAction>
                                            {moment(selectedItem.nomineeDOB).fromNow()}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Grid>
                            }
                            {
                                selectedItem.nomineeRelation &&
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className='bg-primary'><KeyboardHideSharp /></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={selectedItem.nomineeRelation} secondary={"Nominee Relationship"} />
                                    </ListItem>
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                {
                                    selectedItem && selectedItem.walletAddress &&
                                    <Card className='mt-2' variant={"outlined"} style={{ background: "transparent" }}>
                                        <CardHeader avatar={<Avatar style={{ background: "transparent" }}>
                                            <img src={process.env.PUBLIC_URL + "BNB.png"} width="100%" />
                                        </Avatar>}
                                            title={"BNB Wallet"}
                                            subheader="Secured by Bicoince Wallet"
                                        />
                                        <CardContent>
                                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                                <img src={process.env.PUBLIC_URL + "BNB.png"} width={120} />
                                                <h6 className='my-2 overflow-text' onClick={() => {
                                                    navigator.clipboard.writeText(selectedItem.walletAddress);
                                                    Toastr.info("Address copied to clipboard");
                                                }}>
                                                    {selectedItem.walletAddress}
                                                </h6>
                                                {
                                                    walletInfo && walletInfo.coinPrice &&
                                                    <>
                                                        <h4 className='text-success text-bold'>{walletInfo.usdtTokenBalance} USDT </h4>
                                                        <h4 className='text-primary text-bold'>{walletInfo.coinBalance} BNB </h4>
                                                        <h4 className='text-secondary'>{walletInfo.tokenBalance} T369</h4>
                                                    </>
                                                }

                                            </Box>
                                        </CardContent>

                                    </Card>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className='m-2'>
                                    <Button onClick={() => {
                                        Swal.fire({
                                            icon: "question",
                                            title: selectedItem.status === "Active" ? "Suspend User" : "Resume User",
                                            text: `Are you sure want to ${selectedItem.status === "Active" ? "Suspend User" : "Resume User"} ${selectedItem.memberId}`,
                                            confirmButtonText: "Yes Proceed",
                                            showCancelButton: true
                                        }).then((x) => {
                                            if (x.isConfirmed) {
                                                if (selectedItem.status === "Active") {
                                                    dispatch(suspendMemberRequest(selectedItem.memberId));
                                                }
                                                else {
                                                    dispatch(resumeMemberRequest(selectedItem.memberId));
                                                }
                                            }
                                        })
                                    }} className='my-2' variant='outlined' color="default" fullWidth>
                                        {selectedItem.status === "Active" ? "Suspend User" : "Resume User"}
                                    </Button>
                                    <Button onClick={() => {
                                        Swal.fire({
                                            icon: "question",
                                            title: "Reset Password",
                                            text: `Are you sure want to reset password for ${selectedItem.memberId}`,
                                            confirmButtonText: "Yes Proceed",
                                            showCancelButton: true
                                        }).then((x) => {
                                            if (x.isConfirmed) {
                                                dispatch(resetMemberRequest(selectedItem.memberId))
                                            }
                                        })
                                    }} className='my-2' variant='outlined' color="primary" fullWidth>
                                        Reset Password
                                    </Button>
                                    <Button className='my-2'
                                        onClick={() => {
                                            if (selectedItem.walletAddress) {
                                                if (!selectedItem.isTokenApproved) {
                                                    dispatch(approveTokenRequest(selectedItem.memberId))
                                                }
                                            }
                                        }}
                                        disabled={!selectedItem.walletAddress || selectedItem.isTokenApproved}
                                        variant='outlined' color="secondary" fullWidth>
                                        {selectedItem.isTokenApproved ? "Already Approved" :
                                            !selectedItem.walletAddress ? "Wallet not created" :
                                                "Approve Token Request"}
                                    </Button>
                                    <Button className='my-2' variant='outlined' color="secondary" fullWidth>
                                        Join Plan
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    {
                        value === 1 &&
                        <Grid container className='my-4'>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ReferalContainer />
                                <RefereeContainer />
                            </Grid>
                        </Grid>
                    }
                    {
                        value === 2 && <Grid container spacing={2} className='my-4'>
                            {
                                planMembers && planMembers.map((s, index) => {
                                    let planDetail = _.find(plans, z => z._id === s.planId);
                                    let duration = moment(s.maturityDate).diff(moment(s.createdAt), "day");
                                    let completed = moment().diff(moment(s.createdAt), "day");
                                    completed = parseInt(completed * 100 / duration);
                                    return <Grid item md={4} lg={4} sm={12} xs={12}>
                                        <Card variant='outlined'>
                                            <CardHeader
                                                className='m-0 p-1'
                                                avatar={<Avatar className='bg-success'>{index + 1}</Avatar>}
                                                title={planDetail && planDetail.planName}
                                                subheader={planDetail && planDetail.duration + " months"}
                                            />
                                            <Divider />
                                            <CardContent style={{ paddingBottom: 16, padding: 0 }}>
                                                <ul className='stripe-ul'>
                                                    <li>
                                                        <span>Joined Date :</span>
                                                        <span>{moment(s.createdAt).format("ll")}</span>
                                                    </li>
                                                    <li>
                                                        <span>Maturity Date :</span>
                                                        <span>{moment(s.maturityDate).format("ll")}</span>
                                                    </li>
                                                    <li>
                                                        <span>Duration : </span>
                                                        <span>{moment(s.maturityDate).diff(moment(s.createdAt), "month")} Months</span>
                                                    </li>
                                                    <li>
                                                        <span>Amount : </span>
                                                        <span>{s.amount} USD</span>
                                                    </li>
                                                </ul>
                                                <LinearProgress className='mt-2' color="primary" style={{ height: 20 }} value={completed} variant="determinate" />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                })
                            }
                            {
                                planMembers && planMembers.length === 0 &&
                                <div className='w-full'>
                                    <Alert severity='info' title='Plan Information'>
                                        No Plans Found
                                    </Alert>
                                </div>
                            }
                        </Grid>
                    }
                    {
                        value === 3 && <div>
                            {
                                withdraws && withdraws.length === 0 &&
                                <div className='w-full'>
                                    <Alert severity='info' title='Withdraw List'>
                                        No Withdraws Found
                                    </Alert>
                                </div>
                            }
                            {
                                withdraws.map((t) => {
                                    return <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Grid container>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {moment(t.createdAt).format("LLL")}
                                                </Grid>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {t.isToken ? t.amount + " " + t.tokenSymbol : t.amount + " " + t.coinType}
                                                </Grid>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {t.status}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Fingerprint /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.referenceNo} secondary={"Reference No"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.referenceNo);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionId} secondary={"Transaction ID"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.transactionId);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Money /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.amount + " " + (t.isToken ? t.tokenSymbol : t.coinType)} secondary={"Amount"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><CalendarToday /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={moment(t.createdAt).format("lll")} secondary={"Transaction Date"} />
                                                    </ListItem>
                                                </Grid>
                                                {
                                                    t.coinType &&
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <ListItem>
                                                            <ListItemAvatar>
                                                                <Avatar className='bg-success'><Code /></Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText primary={t.transactionhash} secondary={"Transaction Hash"} />
                                                            <ListItemSecondaryAction>
                                                                <a target='_blank' href={getTransactionUrl(t.coinType) + t.transactionhash}>
                                                                    <OpenInNew />
                                                                </a>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Star /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.status} secondary={"Status"} />
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            }
                        </div>
                    }
                    {
                        value === 4 && <div>
                            {
                                swaps && swaps.length === 0 &&
                                <div className='w-full'>
                                    <Alert severity='info' title='Buy/Sell List'>
                                        No Buy/Sell Found
                                    </Alert>
                                </div>
                            }
                            {
                                swaps.map((t) => {
                                    return <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Grid container>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {moment(t.createdAt).format("LLL")}

                                                </Grid>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {t.swapType}
                                                </Grid>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {t.tokenAddress ? t.transactionTokens + " " + t.tokenSymbol : t.transactionCoins + " " + t.coinType}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Fingerprint /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.referenceNo} secondary={"Reference No"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.referenceNo);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionId} secondary={"Transaction ID"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.transactionId);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Money /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionCoins + " " + (t.transactionMode === "USDT" ? "USDT" : t.coinType)} secondary={"Amount"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><AccountBalanceWallet /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionTokens + " " + t.tokenSymbol} secondary={"Amount"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><AccountBalanceWalletRounded /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionCharge + " " + t.coinType} secondary={"Transaction Charge"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><CalendarToday /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={moment(t.createdAt).format("lll")} secondary={"Transaction Date"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><FlipToBackRounded /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.tokenAddress && t.tokenAddress.toString()} secondary={"Token Address"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.tokenAddress);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><FlipToBackRounded /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.createdBy} secondary={"Created By"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.spentHash} secondary={"Sender Hash"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.spentHash);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.receiveHash} secondary={"Receiver Hash"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.receiveHash);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionChargeHash} secondary={"Charge Hash"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.transactionChargeHash);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Star /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.status} secondary={"Status"} />
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            }
                        </div>
                    }
                    {
                        value === 5 && <div>
                            {
                                payouts && payouts.length === 0 &&
                                <div className='w-full'>
                                    <Alert severity='info' title='Payouts List'>
                                        No Payouts Found
                                    </Alert>
                                </div>
                            }
                            {
                                payouts.map((t) => {
                                    return <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Grid container>
                                                <Grid item md={3} lg={3} sm={12} xs={12}>
                                                    {t.type}
                                                </Grid>
                                                <Grid item md={3} lg={3} sm={12} xs={12}>
                                                    {moment(t.createdAt).format("LLL")}
                                                </Grid>
                                                <Grid item md={3} lg={3} sm={12} xs={12}>
                                                    {t.finalAmount + " USDT"}
                                                </Grid>
                                                <Grid item md={3} lg={3} sm={12} xs={12}>
                                                    {t.status}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Fingerprint /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t._id} secondary={"Reference No"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t._id);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.type} secondary={"Type"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Money /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.amount + " USD"} secondary={"Amount"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><CalendarToday /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={moment(t.createdAt).format("lll")} secondary={"Transaction Date"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><FormatAlignCenter /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.createdBy} secondary={"From"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><FlipToBackRounded /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.memberId} secondary={"To"} />
                                                    </ListItem>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.method} secondary={"Method"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.reinvestment + " USD"} secondary={"Reinvestment"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.liquidity + ' USD'} secondary={"Liquidity"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.adminFees + " USD"} secondary={"Gas Fees"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Star /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.status} secondary={"Status"} />
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            }
                        </div>
                    }
                    {
                        value === 6 && <div>
                            {
                                contracts && contracts.length === 0 &&
                                <div className='w-full'>
                                    <Alert severity='info' title='Contracts List'>
                                        No Contracts Found
                                    </Alert>
                                </div>
                            }
                            {
                                contracts.map((t) => {
                                    return <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Grid container>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {t.method}
                                                </Grid>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {moment(t.createdAt).format("LLL")}
                                                </Grid>
                                                <Grid item md={4} lg={4} sm={12} xs={12}>
                                                    {t.status}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Fingerprint /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t._id} secondary={"Reference No"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t._id);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.method} secondary={"Method"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Money /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.amount + " Tokens"} secondary={"Amount"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><CalendarToday /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={moment(t.createdAt).format("lll")} secondary={"Transaction Date"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Email /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.userEmail && t.userEmail} secondary={"Email"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.userEmail);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><FlipToBackRounded /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.createdBy} secondary={"Created By"} />
                                                    </ListItem>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Code /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.transactionHash} secondary={"Transaction Hash"} />
                                                        <ListItemSecondaryAction>
                                                            <FileCopy color="secondary" onClick={() => {
                                                                navigator.clipboard.writeText(t.transactionHash);
                                                                Toastr.info("copied to clipboard");
                                                            }} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Star /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.status} secondary={"Status"} />
                                                    </ListItem>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Deck /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.fromParams && t.fromParams.join(" | ")} secondary={"From Params"} />
                                                    </ListItem>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar className='bg-success'><Deck /></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={t.toParams && t.toParams.join(" | ")} secondary={"To Params"} />
                                                    </ListItem>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                })
                            }
                        </div>
                    }
                </DialogContent>
            </Dialog>
            <TitleBar heading="Members" secondary={""}
                icon={<AccountBalance />}
                action={<Button color="primary" onClick={() => mode === "add" ? setMode("") : setMode("add")} variant='contained'>{
                    mode === "add" ? "View Member" : "Add Member"
                }</Button>}
            />
            <div className={classes.root}>
                {
                    mode === "add" ?
                        <AddMember members={members} /> :
                        mode === "edit" ?
                            <UpdateMember members={members} onClose={() => {
                                setMode("list");
                                setSelecteItem({});
                            }} selectedMember={selectedItem} /> :
                            <MUIDataTable
                                title={"Member's List"}
                                data={datum}
                                columns={columns}
                                options={options}
                            />
                }
            </div >
        </div >
    );
}

