import { MemberActionTypes } from './member.model';
require('dotenv').config();

export const initialState = {
    members: [],
    totalMembers: 0,
    referals: {
        _id: "0",
        firstName: "",
        lastName: "",
        country: "",
        mobileNumber: "",
        email: "",
        memberId: "",
        referal: "",
        children: []
    },
    referees: {
        _id: "0",
        firstName: "",
        lastName: "",
        country: "",
        mobileNumber: "",
        email: "",
        memberId: "",
        referal: "",
        children: []
    },
    isLoading: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MemberActionTypes.FETCH_MEMBERS_REQUEST:
        case MemberActionTypes.APPROVE_TOKEN_REQUEST:
        case MemberActionTypes.SUSPEND_USER_REQUEST:
        case MemberActionTypes.RESUME_USER_REQUEST:
        case MemberActionTypes.RESET_PASSWORD_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case MemberActionTypes.UPDATE_WITHDRAW_STATUS_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case MemberActionTypes.APPROVE_TOKEN_SUCCESS: {
            return {
                ...state,
                isLoading: true
            };
        }

        case MemberActionTypes.FETCH_MEMBERS_SUCCESS: {
            return {
                ...state,
                members: action.payload.members,
                totalMembers: action.payload.totalMembers,
                isLoading: false
            };
        }
        case MemberActionTypes.FETCH_MEMBERS_FAILURE:
        case MemberActionTypes.APPROVE_TOKEN_FAILURE:
        case MemberActionTypes.SUSPEND_USER_FAILURE:
        case MemberActionTypes.RESUME_USER_FAILURE:
        case MemberActionTypes.RESET_PASSWORD_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        case MemberActionTypes.UPDATE_MEMBER_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload.memberId) {
                        t.email = action.payload.email;
                        t.mobileNumber = action.payload.mobileNumber;
                        t.firstName = action.payload.firstName;
                        t.lastName = action.payload.lastName;
                        t.referal = action.payload.referal;
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.SUSPEND_USER_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload.memberId) {
                        t.status = "Inactive";
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.APPROVE_TOKEN_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.userId === action.payload.userId) {
                        t.isTokenApproved = false;
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.RESUME_USER_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload.memberId) {
                        t.status = "Active";
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }

        case MemberActionTypes.UPDATE_WITHDRAW_STATUS_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload) {
                        t.isWithdrawEnabled = !t.isWithdrawEnabled;
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.UPDATE_BUY_STATUS_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload) {
                        t.isBuyEnabled = !t.isBuyEnabled;
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.UPDATE_SELL_STATUS_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload) {
                        t.isSellEnabled = !t.isSellEnabled;
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.UPDATE_STACK_STATUS_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload) {
                        t.isStackEnabled = !t.isStackEnabled;
                    }
                    return t;
                }),
                isLoading: false
            };
        }


        case MemberActionTypes.UPDATE_UNSTACK_STATUS_SUCCESS: {
            return {
                ...state,
                members: state.members.map((t) => {
                    if (t.memberId === action.payload) {
                        t.isUnStackEnabled = !t.isUnStackEnabled;
                    }
                    return t;
                }),
                isLoading: false
            };
        }

        case MemberActionTypes.FETCH_REFERAL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                referals: action.payload && action.payload.length > 0 ? action.payload[0] : state.referals
            };
        }

        case MemberActionTypes.FETCH_REFEREE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                referees: action.payload
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as MemberReducer };