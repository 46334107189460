import { Avatar, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { AccountBalance, AccountBalanceWallet, AccountBox, CalendarToday, Check, Close, Edit, Email, Fingerprint, ListAlt, Phone, Star, Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "underscore";
import { fetchMemberReferalRequest, fetchMembersRequest, updateBuyStatusRequest, updateSellStatusRequest, updateStackStatusRequest, updateUnStackStatusRequest, updateWithdarwStatusRequest } from '../../store/member/member.actions';
import { getMembers } from '../../store/member/member.selector';
import { fetchPlanMemberRequest, fetchPlansRequest } from '../../store/plan/plan.actions';
import { getPlanMembers, getPlans } from '../../store/plan/plan.selector';
import TitleBar from '../base/TitleBar';
import Swal from "sweetalert2";
import Loader from '../base/loader';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function MemberTransContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState("Profile");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(null);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        dispatch(fetchMembersRequest({
            page: 0,
            pageSize: 10
        }));
        dispatch(fetchPlansRequest());
    }, [])

    const { members, planMembers, plans, isLoading, totalMembers } = useSelector((state) => {
        return {
            members: getMembers(state),
            planMembers: getPlanMembers(state),
            plans: getPlans(state),
            totalMembers: state.MemberReducer.totalMembers,
            isLoading: state.MemberReducer.isLoading
        }
    }, []);

    useEffect(() => {
        setOpen(false);
        setSelecteItem({});
        setMode("");
    }, [members]);

    useEffect(() => {
        if (selectedItem.memberId) {
            dispatch(fetchMemberReferalRequest(selectedItem.memberId));
            dispatch(fetchPlanMemberRequest(selectedItem._id));
        }
    }, [selectedItem])

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(members, x => x._id === value);
                    return (
                        <>


                            <Visibility
                                className={classes.icon}
                                color="secondary"
                                onClick={() => {
                                    setOpen(true);
                                    setSelecteItem(dep);
                                    setMode("Profile");
                                }}
                            />&nbsp;
                            <ListAlt
                                className={classes.icon}
                                color="action"
                                onClick={() => {
                                    setOpen(true);
                                    setSelecteItem(dep);
                                    setMode("Referal");
                                }}
                            />
                        </>
                    );
                }
            }
        });
    };


    const columns = [{
        name: 'memberId',
        label: 'Member #',
        options: {
            filter: true,
            sort: false
        }
    },
    {
        name: 'name',
        label: 'Member Name',
        options: {
            filter: true,
            sort: false,

        }
    },
    // {
    //     name: 'phone',
    //     label: 'Mobile',
    //     options: {
    //         filter: true,
    //         sort: false
    //     }
    // },
    // {
    //     name: 'planMember',
    //     label: 'Plans',
    //     options: {
    //         filter: true,
    //         sort: false,
    //         customBodyRender: (value) => {
    //             return value.length
    //         }
    //     }
    // },
    {
        name: 'memberId',
        label: 'Withdraw',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let mem = _.find(members, t => t.memberId === value);
                let isWithdrawEnabled = mem.isWithdrawEnabled;
                return <IconButton size="small" onClick={() => {
                    Swal.fire({
                        title: "Withdraw Status",
                        text: `Are you sure want to update withdraw status to  ${isWithdrawEnabled ? "Disabled" : "Enabled"}?`,
                        icon: "question",
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Yes Procees"
                    }).then((s) => {
                        if (s.isConfirmed) {
                            dispatch(updateWithdarwStatusRequest(value))
                        }
                    })
                }} variant={isWithdrawEnabled ? "contained" : "outlined"}
                    color={isWithdrawEnabled ? "primary" : "secondary"}
                >
                    {isWithdrawEnabled ? <Check /> : <Close />}
                </IconButton>
            }
        }
    },
    {
        name: 'memberId',
        label: 'Buy',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let mem = _.find(members, t => t.memberId === value);
                let isBuyEnabled = mem.isBuyEnabled;
                return <IconButton size="small" onClick={() => {
                    Swal.fire({
                        title: "Buy Status",
                        text: `Are you sure want to update Buy status to  ${isBuyEnabled ? "Disabled" : "Enabled"}?`,
                        icon: "question",
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Yes Procees"
                    }).then((s) => {
                        if (s.isConfirmed) {
                            dispatch(updateBuyStatusRequest(value))
                        }
                    })
                }} variant={isBuyEnabled ? "contained" : "outlined"}
                    color={isBuyEnabled ? "primary" : "secondary"}
                >
                    {isBuyEnabled ? <Check /> : <Close />}
                </IconButton>
            }
        }
    },
    {
        name: 'memberId',
        label: 'Sell',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let mem = _.find(members, t => t.memberId === value);
                let isSellEnabled = mem.isSellEnabled;
                return <IconButton size="small" onClick={() => {
                    Swal.fire({
                        title: "Sell Status",
                        text: `Are you sure want to update Sell status to  ${isSellEnabled ? "Disabled" : "Enabled"}?`,
                        icon: "question",
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Yes Procees"
                    }).then((s) => {
                        if (s.isConfirmed) {
                            dispatch(updateSellStatusRequest(value))
                        }
                    })
                }} variant={isSellEnabled ? "contained" : "outlined"}
                    color={isSellEnabled ? "primary" : "secondary"}
                >
                    {isSellEnabled ? <Check /> : <Close />}
                </IconButton>
            }
        }
    },
    {
        name: 'memberId',
        label: 'Stack',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let mem = _.find(members, t => t.memberId === value);
                let isStackEnabled = mem.isStackEnabled;
                return <IconButton size="small" onClick={() => {
                    Swal.fire({
                        title: "Stack Status",
                        text: `Are you sure want to update Stack status to  ${isStackEnabled ? "Disabled" : "Enabled"}?`,
                        icon: "question",
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Yes Procees"
                    }).then((s) => {
                        if (s.isConfirmed) {
                            dispatch(updateStackStatusRequest(value))
                        }
                    })
                }} variant={isStackEnabled ? "contained" : "outlined"}
                    color={isStackEnabled ? "primary" : "secondary"}
                >
                    {isStackEnabled ? <Check /> : <Close />}
                </IconButton>
            }
        }
    },
    {
        name: 'memberId',
        label: 'UnStack',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
                let mem = _.find(members, t => t.memberId === value);
                let isUnStackEnabled = mem.isUnStackEnabled;
                return <IconButton size="small" onClick={() => {
                    Swal.fire({
                        title: "UnStack Status",
                        text: `Are you sure want to update UnStack status to  ${isUnStackEnabled ? "Disabled" : "Enabled"}?`,
                        icon: "question",
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Yes Procees"
                    }).then((s) => {
                        if (s.isConfirmed) {
                            dispatch(updateUnStackStatusRequest(value))
                        }
                    })
                }} variant={isUnStackEnabled ? "contained" : "outlined"}
                    color={isUnStackEnabled ? "primary" : "secondary"}
                >
                    {isUnStackEnabled ? <Check /> : <Close />}
                </IconButton>
            }
        }
    },
    {
        name: 'planMember',
        label: 'Activated On',
        options: {
            filter: true,
            sort: false,
            customBodyRender: (value) => {
                if (value.length === 0) {
                    return "Not Activated";
                }
                else {
                    if (value.length > 1) {
                        let latestDate = _.sortBy(value, t => moment(t.createdAt).unix());
                        latestDate = latestDate[0].createdAt;
                        return moment(latestDate).format("LLL")
                    }
                    else {
                        let latestDate = value[0].createdAt;
                        return moment(latestDate).format("LLL")
                    }

                }

            }
        }
    },


        // {
        //     name: 'refCount',
        //     label: 'Referal Count',
        //     options: {
        //         filter: true,
        //         sort: false,
        //     }
        // },
        // actionRenderer()
    ];

    const datum = _.sortBy(members, t => moment.unix(t.createdAt)).reverse().map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).fromNow(),
            memberId: item.memberId,
            deposit: item.depositAmount || 0,
            refCount: item.refCount,
            phone: item.mobileNumber,
            email: item.email,
            name: item.firstName + " " + item.lastName,
            planMember: item.planMember,
        };
    });

    const options = {
        selectableRows: false,
        rowsPerPage: rowsPerPage,
        serverSide: true,
        filter: false,
        count: totalMembers,
        onChangeRowsPerPage: (numberOfRows) => {
            console.log(numberOfRows, "num");
            setRowsPerPage(numberOfRows);
            dispatch(fetchMembersRequest({
                page: page,
                pageSize: numberOfRows
            }));
        },
        onSearchClose: () => {
            setSearchText("");
            dispatch(fetchMembersRequest({
                page: page,
                pageSize: rowsPerPage,
                search: ""
            }));
        },
        onTableChange: (action, tableState) => {
            console.log(tableState.page, "table state");
            if (action === "changePage") {
                setPage(tableState.page);
                dispatch(fetchMembersRequest({
                    page: tableState.page,
                    pageSize: rowsPerPage,
                    search: searchText
                }));
            } else if (action === "search") {
                setSearchText(tableState.searchText);
                if (tableState.searchText && (tableState.searchText.length > 2 || tableState.searchText.length === 0)) {
                    dispatch(fetchMembersRequest({
                        page: page,
                        pageSize: rowsPerPage,
                        search: tableState.searchText
                    }));
                }
            } else {
                console.log(tableState);
            }
        }
    };

    return (
        <div className='mb-4 pb-4'>
            {
                isLoading && <Loader />
            }

            <TitleBar heading="Members" secondary={""}
                icon={<AccountBalance />}
            />
            <div className={classes.root}>
                <MUIDataTable
                    title={"Member's List"}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div >
        </div >
    );
}

