export const API_URL = process.env.REACT_APP_API_URL;

export function getLoginUrl() {
    return API_URL + '/login';
}

export function get2FASetup() {
    return API_URL + '/users/2fa/setup';
}

export function getSendEmailOTP() {
    return API_URL + '/users/set/email/otp';
}

export function getSendPhoneOTP() {
    return API_URL + '/users/set/phone/otp';
}

export function setSendEmailOTP(otp) {
    return API_URL + '/users/verify/email/otp?otp=' + otp;
}

export function setSendPhoneOTP(otp) {
    return API_URL + '/users/verify/phone/otp?otp=' + otp;
}

export function verify2FAUrl() {
    return API_URL + '/users/2fa/verify';
}

export function getStatUrl() {
    return API_URL + '/users/wallet/stats';
}

export function getForgotPasswordURI(email) {
    return API_URL + '/users/password/forgot?email=' + email;
}

export function getChangePasswordURI() {
    return API_URL + '/users/password/update';
}