import { Badge, Card, CardContent, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unflatten } from '../../helpers/utility';
import { getMemberReferals } from '../../store/member/member.selector';
import RefereeContainer from './Refer.Container';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ReferalContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const { referals } = useSelector((state) => {
        return {
            referals: getMemberReferals(state)
        }
    }, [])

    const renderTree = (nodes) => {
        return <TreeItem key={nodes._id} nodeId={nodes._id} label={
            <Card elevation={10} className="my-2">
                <CardContent className='py-0'>
                    <List disablePadding>
                        <ListItem disableGutters className='py-0'>
                            <ListItemText primary={nodes.memberId} secondary={nodes.firstName + " " + nodes.lastName} />
                        </ListItem>
                        <ListItemSecondaryAction>
                            <Badge badgeContent={nodes.children && nodes.children.length} color="secondary">

                            </Badge>
                        </ListItemSecondaryAction>
                    </List>
                </CardContent>
            </Card>}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </TreeItem>
    }

    const data = unflatten(referals);

    let selectedRoots = referals.children.map((x) => x._id);

    const columns = ["Member #", "Name", "Email", "Mobile Number"];

    const datum = referals.children.filter((x) => x.depth === 0).map((y) => {
        return [y.memberId, y.firstName + " " + y.lastName, y.email, y.mobileNumber];
    });

    const options = {
        selectableRows: false
    };

    return (
        <div>
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="simple tabs example">
                        <Tab fullWidth label="Referal List" {...a11yProps(0)} />
                        <Tab fullWidth label="Referal Tree" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <RefereeContainer />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TreeView
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpanded={selectedRoots}
                        defaultExpandIcon={<ChevronRight />}
                    >
                        {renderTree(data)}
                    </TreeView>
                </TabPanel>
            </div>
        </div >
    );
}

