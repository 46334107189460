export const MemberActionTypes = {
  FETCH_MEMBERS_REQUEST: "@@User/FETCH_MEMBERS_REQUEST",
  FETCH_MEMBERS_SUCCESS: "@@User/FETCH_MEMBERS_SUCCESS",
  FETCH_MEMBERS_FAILURE: "@@User/FETCH_MEMBERS_FAILURE",

  UPDATE_MEMBER_REQUEST: "@@User/UPDATE_MEMBER_REQUEST",
  UPDATE_MEMBER_SUCCESS: "@@User/UPDATE_MEMBER_SUCCESS",
  UPDATE_MEMBER_FAILURE: "@@User/UPDATE_MEMBER_FAILURE",

  SUSPEND_USER_REQUEST: "@@User/SUSPEND_USER_REQUEST",
  SUSPEND_USER_SUCCESS: "@@User/SUSPEND_USER_SUCCESS",
  SUSPEND_USER_FAILURE: "@@User/SUSPEND_USER_FAILURE",

  RESUME_USER_REQUEST: "@@User/RESUME_USER_REQUEST",
  RESUME_USER_SUCCESS: "@@User/RESUME_USER_SUCCESS",
  RESUME_USER_FAILURE: "@@User/RESUME_USER_FAILURE",

  RESET_PASSWORD_REQUEST: "@@User/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "@@User/RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "@@User/RESET_PASSWORD_FAILURE",

  APPROVE_TOKEN_REQUEST: "@@User/APPROVE_TOKEN_REQUEST",
  APPROVE_TOKEN_SUCCESS: "@@User/APPROVE_TOKEN_SUCCESS",
  APPROVE_TOKEN_FAILURE: "@@User/APPROVE_TOKEN_FAILURE",

  FETCH_REFERAL_REQUEST: "@@User/FETCH_REFERAL_REQUEST",
  FETCH_REFERAL_SUCCESS: "@@User/FETCH_REFERAL_SUCCESS",
  FETCH_REFERAL_FAILURE: "@@User/FETCH_REFERAL_FAILURE",

  FETCH_REFEREE_REQUEST: "@@User/FETCH_REFEREE_REQUEST",
  FETCH_REFEREE_SUCCESS: "@@User/FETCH_REFEREE_SUCCESS",
  FETCH_REFEREE_FAILURE: "@@User/FETCH_REFEREE_FAILURE",

  UPDATE_WITHDRAW_STATUS_REQUEST: "@@User/UPDATE_WITHDRAW_STATUS_REQUEST",
  UPDATE_WITHDRAW_STATUS_SUCCESS: "@@User/UPDATE_WITHDRAW_STATUS_SUCCESS",
  UPDATE_WITHDRAW_STATUS_FAILURE: "@@User/UPDATE_WITHDRAW_STATUS_FAILURE",

  FETCH_WALLET_REQUEST: "@@User/FETCH_WALLET_REQUEST",
  FETCH_WALLET_SUCCESS: "@@User/FETCH_WALLET_SUCCESS",
  FETCH_WALLET_FAILURE: "@@User/FETCH_WALLET_FAILURE",

  REGISTER_MEMBER_REQUEST: "@@User/REGISTER_MEMBER_REQUEST",
  REGISTER_MEMBER_SUCCESS: "@@User/REGISTER_MEMBER_SUCCESS",
  REGISTER_MEMBER_FAILURE: "@@User/REGISTER_MEMBER_FAILURE",

  UPDATE_BUY_STATUS_REQUEST: "@@User/UPDATE_BUY_STATUS_REQUEST",
  UPDATE_BUY_STATUS_SUCCESS: "@@User/UPDATE_BUY_STATUS_SUCCESS",
  UPDATE_BUY_STATUS_FAILURE: "@@User/UPDATE_BUY_STATUS_FAILURE",

  UPDATE_SELL_STATUS_REQUEST: "@@User/UPDATE_SELL_STATUS_REQUEST",
  UPDATE_SELL_STATUS_SUCCESS: "@@User/UPDATE_SELL_STATUS_SUCCESS",
  UPDATE_SELL_STATUS_FAILURE: "@@User/UPDATE_SELL_STATUS_FAILURE",


  UPDATE_STACK_STATUS_REQUEST: "@@User/UPDATE_STACK_STATUS_REQUEST",
  UPDATE_STACK_STATUS_SUCCESS: "@@User/UPDATE_STACK_STATUS_SUCCESS",
  UPDATE_STACK_STATUS_FAILURE: "@@User/UPDATE_STACK_STATUS_FAILURE",

  UPDATE_UNSTACK_STATUS_REQUEST: "@@User/UPDATE_UNSTACK_STATUS_REQUEST",
  UPDATE_UNSTACK_STATUS_SUCCESS: "@@User/UPDATE_UNSTACK_STATUS_SUCCESS",
  UPDATE_UNSTACK_STATUS_FAILURE: "@@User/UPDATE_UNSTACK_STATUS_FAILURE",
};

