export const UserActionTypes = {
  REGISTER_USER_REQUEST: "@@User/REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "@@User/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "@@User/REGISTER_USER_FAILURE",

  FETCH_USER_REQUEST: "@@User/FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "@@User/FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "@@User/FETCH_USER_FAILURE",
  
  FETCH_USERS_REQUEST: "@@User/FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS: "@@User/FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE: "@@User/FETCH_USERS_FAILURE",

  UPDATE_USER_REQUEST: "@@User/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "@@User/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "@@User/UPDATE_USER_FAILURE",
};

