import { Avatar, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import { Close, Email } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { decodeToken } from '../../helpers/utility';
import { getAccessToken } from '../../store/authentication/auth.selector';
import VerifyPin from './verifyPin';

export default function OTPVerifyEmail(props) {

  const { accessToken } = useSelector((state) => {
    return {
      accessToken: getAccessToken(state),
    }
  })

  const user = decodeToken(accessToken);

  return (
    <Container maxWidth="sm" className='mt-4 pt-4'>
      <Card elevation={10}>
        <CardHeader
          avatar={<Avatar>
            <Email />
          </Avatar>}
          title={"Email Verification"} subheader="6 Digit OTP"
          action={<Close onClick={() => props.onClose()} />}
        />
        <Divider />
        <CardContent>
          <h5 className="text-center my-2 text-secondary">
            OTP has been sent to your registered Email <b className='text-primary'>{user.email}</b>. Please Verify
          </h5>
          <VerifyPin
            type="email"
            onComplete={() => {
              Swal.fire({
                icon: "success",
                title: "Email Verification Done",
                showConfirmButton: true,
                confirmButtonText: "Close"
              }).then((x) => {
                if (x.isConfirmed) {
                  props.onComplete()
                }
              })

            }}
            onClose={() => {
              props.onClose()
            }}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
