import HttpHelper from '../../helpers/httpHelper';
require('dotenv').config();
const headerInfo = {};

class MemberAPI {
    static async RegisterMember(req) {
        let url = process.env.REACT_APP_API_URL + '/member';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req);
    }

    static async GetMemberDetail(memberId) {
        let url = process.env.REACT_APP_API_URL + '/member/referral/' + memberId;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetStats(req) {
        let url = process.env.REACT_APP_API_URL + '/stats';
        return HttpHelper.httpRequest(url, 'GET', headerInfo, req);
    }

    static async GetMembers(query) {
        let url = process.env.REACT_APP_API_URL + `/members?page=${query.page}&pageSize=${query.pageSize}&search=${query.search || ""}`;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdateWithdrawStatus(id) {
        let url = process.env.REACT_APP_API_URL + '/members/enable/withdraw/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async SuspendUser(id) {
        let url = process.env.REACT_APP_API_URL + '/members/suspend/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async ResumeUser(id) {
        let url = process.env.REACT_APP_API_URL + '/members/resume/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async ResetPassword(id) {
        let url = process.env.REACT_APP_API_URL + '/members/reset/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async ApproveContract(id) {
        let url = process.env.REACT_APP_API_URL + '/members/contract/approve/' + id;
        return HttpHelper.httpRequest(url, 'POST', headerInfo, {
            amount: 1000000
        });
    }


    static async UpdateBuyStatus(id) {
        let url = process.env.REACT_APP_API_URL + '/members/enable/buy/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async UpdateSellStatus(id) {
        let url = process.env.REACT_APP_API_URL + '/members/enable/sell/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async UpdateStackStatus(id) {
        let url = process.env.REACT_APP_API_URL + '/members/enable/stack/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async UpdateUnStackStatus(id) {
        let url = process.env.REACT_APP_API_URL + '/members/enable/unstack/' + id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo);
    }

    static async GetWalletInfo(id) {
        let url = process.env.REACT_APP_API_URL + '/members/wallet/info/' + id;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdateCurrentUser(req) {
        let url = process.env.REACT_APP_API_URL + '/member/' + req.id;
        return HttpHelper.httpRequest(url, 'PATCH', headerInfo, req);
    }

    static async GetCurrentUserReferals(id) {
        let url = process.env.REACT_APP_API_URL + '/member/children/' + id;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async GetCurrentUserReferees(id) {
        let url = process.env.REACT_APP_API_URL + '/member/parent/' + id;
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }
}

export default MemberAPI;
