import { Avatar, Dialog, DialogContent, DialogTitle, Divider, Grid, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { AccountBalance, CalendarToday, Close, Code, Email, FileCopy, Fingerprint, FlipToBackRounded, FormatAlignCenter, Money, Star, TransferWithinAStation, Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from "toastr";
import _ from "underscore";
import { fetchPayoutRequest } from '../../store/transaction/transaction.actions';
import { getPayoutRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';
import Loader from '../base/loader';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function PayoutContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(null);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        dispatch(fetchPayoutRequest({
            page: 0,
            pageSize: 10
        }))
    }, [])

    const { payouts, isLoading, totalPayouts } = useSelector((state) => {
        return {
            payouts: getPayoutRequests(state),
            isLoading: state.TransactionReducer.isLoading,
            totalPayouts: state.TransactionReducer.totalPayouts,
        }
    }, []);

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(payouts, x => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        });
    };


    const columns = [
        {
            name: 'type',
            label: 'Type',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'createdAt',
            label: 'Transaction Date',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'from',
            label: 'From',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'to',
            label: 'To',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Reward (USD)',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        actionRenderer()
    ];

    const datum = _.sortBy(payouts, t => moment(t.createdAt)).reverse().filter((s) => s.planMembers.length > 0).map((item) => {
        return {
            id: item._id,
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            from: item.createdBy,
            to: item.memberId,
            coins: item.amount,
            type: item.type,
            status: item.status,
        };
    });

    const options = {
        selectableRows: false,
        rowsPerPage: rowsPerPage,
        serverSide: true,
        filter: false,
        count: totalPayouts,
        onChangeRowsPerPage: (numberOfRows) => {
            console.log(numberOfRows, "num");
            setRowsPerPage(numberOfRows);
            dispatch(fetchPayoutRequest({
                page: page,
                pageSize: numberOfRows
            }));
        },
        onSearchClose: () => {
            setSearchText("");
            dispatch(fetchPayoutRequest({
                page: page,
                pageSize: rowsPerPage,
                search: ""
            }));
        },
        onTableChange: (action, tableState) => {
            console.log(tableState.page, "table state");
            if (action === "changePage") {
                setPage(tableState.page);
                dispatch(fetchPayoutRequest({
                    page: tableState.page,
                    pageSize: rowsPerPage,
                    search: searchText
                }));
            } else if (action === "search") {
                setSearchText(tableState.searchText);
                if (tableState.searchText && (tableState.searchText.length > 2 || tableState.searchText.length === 0)) {
                    dispatch(fetchPayoutRequest({
                        page: page,
                        pageSize: rowsPerPage,
                        search: tableState.searchText
                    }));
                }
            } else {
                console.log(tableState);
            }
        }
    };

    const selectedPlan = selectedItem && selectedItem.planMembers && selectedItem.planMembers[0];
    let currency = "USDT";

    return (
        <div className='mb-4 pb-4'>
            {
                isLoading && <Loader />
            }
            <Dialog maxWidth="md" fullWidth open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>
                    Payout Transaction <Close className='mt-1 float-right' onClick={() => setOpen(false)} />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Fingerprint /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem._id} secondary={"Reference No"} />
                                <ListItemSecondaryAction>
                                    <FileCopy color="secondary" onClick={() => {
                                        navigator.clipboard.writeText(selectedItem._id);
                                        Toastr.info("copied to clipboard");
                                    }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.type} secondary={"Type"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Money /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.amount + " USD"} secondary={"Amount"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><CalendarToday /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={moment(selectedItem.createdAt).format("lll")} secondary={"Transaction Date"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><FormatAlignCenter /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.createdBy} secondary={"From"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><FlipToBackRounded /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.memberId} secondary={"To"} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Code /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.method} secondary={"Method"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Code /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.reinvestment + " USD"} secondary={"Reinvestment"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Code /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.liquidity + ' USD'} secondary={"Liquidity"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Code /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.adminFees + " USD"} secondary={"Gas Fees"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Star /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.status} secondary={"Status"} />
                            </ListItem>
                        </Grid>
                    </Grid>
                    {
                        selectedPlan && selectedPlan._id &&
                        <>
                            <ul className='stripe-ul'>
                                <li>
                                    <span>Joined Date :</span>
                                    <span>{moment(selectedPlan.createdAt).format("ll")}</span>
                                </li>
                                <li>
                                    <span>Maturity Date :</span>
                                    <span>{moment(selectedPlan.maturityDate).format("ll")}</span>
                                </li>
                                <li>
                                    <span>Duration : </span>
                                    <span>{moment(selectedPlan.maturityDate).diff(moment(selectedPlan.createdAt), "month")} Months</span>
                                </li>
                                <li>
                                    <span>Amount : </span>
                                    <span>{selectedPlan.amount} USDT</span>
                                </li>
                            </ul>
                            <table className='table table-striped mt-2'>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Deposit Amount</th>
                                        <th>Interest Gained</th>
                                        <th>Interest Withdrawable</th>
                                        <th>Reinvest Amount</th>
                                        <th>Withdraw Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedPlan.returns && selectedPlan.returns.map((s, index) => {
                                        return <tr>
                                            <td>{index + 1}</td>
                                            <td>{s.date}</td>
                                            <td>{s.amount} {currency}</td>
                                            <td>{s.interest} {currency}</td>
                                            <td>{s.withdrawalAmount} {currency}</td>
                                            <td>{s.reinvest} {currency}</td>
                                            <td>
                                                {s.status}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </>
                    }
                </DialogContent>
            </Dialog>
            <TitleBar heading="Payout Transactions" secondary={""}
                icon={<AccountBalance />} />
            <div className={classes.root}>
                <MUIDataTable
                    title={"Payout Transactions List"}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div >
    );
}

