import HttpHelper from '../../helpers/httpHelper';
require('dotenv').config();
const headerInfo = {};
class AuthAPI {
    static async Login(request) {
        let url = process.env.REACT_APP_API_URL + '/login';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, request);
    }

    static async Logout(id) {
        let url = process.env.REACT_APP_API_URL + '/logout';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }
}

export default AuthAPI;
