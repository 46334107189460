import { action } from "typesafe-actions";
import { MemberActionTypes } from "./member.model";

export const fetchMembersRequest = (req) => action(MemberActionTypes.FETCH_MEMBERS_REQUEST, req);
export const fetchMembersSuccess = (res) => action(MemberActionTypes.FETCH_MEMBERS_SUCCESS, res);
export const fetchMembersFailure = (err) => action(MemberActionTypes.FETCH_MEMBERS_FAILURE, err);

export const updateMemberRequest = (req) => action(MemberActionTypes.UPDATE_MEMBER_REQUEST, req);
export const updateMemberSuccess = (res) => action(MemberActionTypes.UPDATE_MEMBER_SUCCESS, res);
export const updateMemberFailure = (err) => action(MemberActionTypes.UPDATE_MEMBER_FAILURE, err);

export const suspendMemberRequest = (req) => action(MemberActionTypes.SUSPEND_USER_REQUEST, req);
export const suspendMemberSuccess = (res) => action(MemberActionTypes.SUSPEND_USER_SUCCESS, res);
export const suspendMemberFailure = (err) => action(MemberActionTypes.SUSPEND_USER_FAILURE, err);

export const resumeMemberRequest = (req) => action(MemberActionTypes.RESUME_USER_REQUEST, req);
export const resumeMemberSuccess = (res) => action(MemberActionTypes.RESUME_USER_SUCCESS, res);
export const resumeMemberFailure = (err) => action(MemberActionTypes.RESET_PASSWORD_FAILURE, err);

export const resetMemberRequest = (req) => action(MemberActionTypes.RESET_PASSWORD_REQUEST, req);
export const resetMemberSuccess = (res) => action(MemberActionTypes.RESET_PASSWORD_SUCCESS, res);
export const resetMemberFailure = (err) => action(MemberActionTypes.RESET_PASSWORD_FAILURE, err);

export const approveTokenRequest = (req) => action(MemberActionTypes.APPROVE_TOKEN_REQUEST, req);
export const approveTokenSuccess = (res) => action(MemberActionTypes.APPROVE_TOKEN_SUCCESS, res);
export const approveTokenFailure = (err) => action(MemberActionTypes.APPROVE_TOKEN_FAILURE, err);

export const fetchMemberReferalRequest = (req) => action(MemberActionTypes.FETCH_REFERAL_REQUEST, req);
export const fetchMemberReferalSuccess = (res) => action(MemberActionTypes.FETCH_REFERAL_SUCCESS, res);
export const fetchMemberReferalFailure = (err) => action(MemberActionTypes.FETCH_REFERAL_FAILURE, err);

export const fetchMemberRefereeRequest = (req) => action(MemberActionTypes.FETCH_REFEREE_REQUEST, req);
export const fetchMemberRefereeSuccess = (res) => action(MemberActionTypes.FETCH_REFEREE_SUCCESS, res);
export const fetchMemberRefereeFailure = (err) => action(MemberActionTypes.FETCH_REFEREE_FAILURE, err);

export const fetchMemberWalletRequest = (req) => action(MemberActionTypes.FETCH_WALLET_REQUEST, req);
export const fetchMemberWalletSuccess = (res) => action(MemberActionTypes.FETCH_WALLET_SUCCESS, res);
export const fetchMemberWalletFailure = (err) => action(MemberActionTypes.FETCH_WALLET_FAILURE, err);

export const registerMemberRequest = (req) => action(MemberActionTypes.REGISTER_MEMBER_REQUEST, req);
export const registerMemberSuccess = (res) => action(MemberActionTypes.REGISTER_MEMBER_SUCCESS, res);
export const registerMemberFailure = (err) => action(MemberActionTypes.REGISTER_MEMBER_FAILURE, err);

export const updateWithdarwStatusRequest = (req) => action(MemberActionTypes.UPDATE_WITHDRAW_STATUS_REQUEST, req);
export const updateWithdarwStatusSuccess = (res) => action(MemberActionTypes.UPDATE_WITHDRAW_STATUS_SUCCESS, res);
export const updateWithdarwStatusFailure = (err) => action(MemberActionTypes.UPDATE_WITHDRAW_STATUS_FAILURE, err);

export const updateBuyStatusRequest = (req) => action(MemberActionTypes.UPDATE_BUY_STATUS_REQUEST, req);
export const updateBuyStatusSuccess = (res) => action(MemberActionTypes.UPDATE_BUY_STATUS_SUCCESS, res);
export const updateBuyStatusFailure = (err) => action(MemberActionTypes.UPDATE_BUY_STATUS_FAILURE, err);

export const updateSellStatusRequest = (req) => action(MemberActionTypes.UPDATE_SELL_STATUS_REQUEST, req);
export const updateSellStatusSuccess = (res) => action(MemberActionTypes.UPDATE_SELL_STATUS_SUCCESS, res);
export const updateSellStatusFailure = (err) => action(MemberActionTypes.UPDATE_SELL_STATUS_FAILURE, err);

export const updateStackStatusRequest = (req) => action(MemberActionTypes.UPDATE_STACK_STATUS_REQUEST, req);
export const updateStackStatusSuccess = (res) => action(MemberActionTypes.UPDATE_STACK_STATUS_SUCCESS, res);
export const updateStackStatusFailure = (err) => action(MemberActionTypes.UPDATE_STACK_STATUS_FAILURE, err);

export const updateUnStackStatusRequest = (req) => action(MemberActionTypes.UPDATE_UNSTACK_STATUS_REQUEST, req);
export const updateUnStackStatusSuccess = (res) => action(MemberActionTypes.UPDATE_UNSTACK_STATUS_SUCCESS, res);
export const updateUnStackStatusFailure = (err) => action(MemberActionTypes.UPDATE_UNSTACK_STATUS_FAILURE, err);






