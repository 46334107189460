import { Avatar, Dialog, DialogContent, DialogTitle, Divider, Grid, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import { AccountBalance, CalendarToday, Close, Code, Deck, Email, FileCopy, Fingerprint, FlipToBackRounded, Money, Star, TransferWithinAStation, Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Toastr from "toastr";
import _ from "underscore";
import { fetchContractRequest } from '../../store/transaction/transaction.actions';
import { getContractRequests } from '../../store/transaction/transaction.selector';
import TitleBar from '../base/TitleBar';
import Loader from '../base/loader';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ContractContainer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selectedItem, setSelecteItem] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchContractRequest("Approved"))
    }, [])

    const { contracts, isLoading } = useSelector((state) => {
        return {
            contracts: getContractRequests(state),
            isLoading: state.TransactionReducer.isLoading
        }
    }, []);

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let dep = _.find(contracts, x => x._id === value);
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => {
                                setOpen(true);
                                setSelecteItem(dep);
                            }}
                        />
                    );
                }
            }
        });
    };


    const columns = [
        {
            name: 'createdAt',
            label: 'Transaction Date',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'createdBy',
            label: 'User',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Coins',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'method',
            label: 'Method',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            }
        },
        actionRenderer()
    ];

    const datum = _.sortBy(contracts, t => moment(t.createdAt)).reverse().map((item) => {
        return {
            id: item._id,
            createdBy: item.createdBy,
            createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
            coins: item.amount + ' Tokens',
            method: item.method,
            tokens: item.transactionTokens + ' ' + item.tokenSymbol,
            transactionCharge: item.transactionCharge + ' ' + item.coinType,
            spentHash: item.spentHash,
            receiveHash: item.receiveHash,
            status: item.status,
        };
    });

    const options = {
        selectableRows: false
    };

    return (
        <div className='mb-4 pb-4'>
            {
                isLoading && <Loader />
            }
            <Dialog maxWidth="md" fullWidth open={open} onClose={() => {
                setOpen(false)
            }}>
                <DialogTitle>
                    Contract Transaction <Close className='mt-1 float-right' onClick={() => setOpen(false)} />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Fingerprint /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem._id} secondary={"Reference No"} />
                                <ListItemSecondaryAction>
                                    <FileCopy color="secondary" onClick={() => {
                                        navigator.clipboard.writeText(selectedItem._id);
                                        Toastr.info("copied to clipboard");
                                    }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><TransferWithinAStation /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.method} secondary={"Method"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Money /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.amount + " Tokens"} secondary={"Amount"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><CalendarToday /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={moment(selectedItem.createdAt).format("lll")} secondary={"Transaction Date"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Email /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.userEmail && selectedItem.userEmail} secondary={"Email"} />
                                <ListItemSecondaryAction>
                                    <FileCopy color="secondary" onClick={() => {
                                        navigator.clipboard.writeText(selectedItem.userEmail);
                                        Toastr.info("copied to clipboard");
                                    }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><FlipToBackRounded /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.createdBy} secondary={"Created By"} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Code /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.transactionHash} secondary={"Transaction Hash"} />
                                <ListItemSecondaryAction>
                                    <FileCopy color="secondary" onClick={() => {
                                        navigator.clipboard.writeText(selectedItem.transactionHash);
                                        Toastr.info("copied to clipboard");
                                    }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Star /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.status} secondary={"Status"} />
                            </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Deck /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.fromParams && selectedItem.fromParams.join(" | ")} secondary={"From Params"} />
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className='bg-success'><Deck /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={selectedItem.toParams && selectedItem.toParams.join(" | ")} secondary={"To Params"} />
                            </ListItem>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <TitleBar heading="Contract Transactions" secondary={""}
                icon={<AccountBalance />} />
            <div className={classes.root}>
                <MUIDataTable
                    title={"Contract Transactions List"}
                    data={datum}
                    columns={columns}
                    options={options}
                />
            </div>
        </div >
    );
}

