import { Avatar, Card, CardContent, CardHeader, Container, Divider } from '@material-ui/core';
import { Close, MobileFriendly } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { IsNullOrEmpty, clearToken, decodeToken } from '../../helpers/utility';
import { getAccessToken } from '../../store/authentication/auth.selector';
import UserAPI from '../../store/user/user.api';
import OTPVerifyEmail from './otp-verify-email';
import OTPVerifyPhone from './otp-verify-phone';
import VerifyPin from './verifyPin';

export default function OTPVerify() {
  const [QRCode, SetQRCode] = useState("");
  const [mode, setMode] = useState("");

  const { accessToken } = useSelector((state) => {
    return {
      accessToken: getAccessToken(state),
    }
  })

  useEffect(() => {
    if (!IsNullOrEmpty(accessToken)) {
      const user = decodeToken(accessToken);
      if (!user.isEmailVerified) {
        UserAPI.SetEmailOTP();
        setMode("Email");
      }
      else if (!user.isMobileVerified) {
        UserAPI.SetPhoneOTP();
        setMode("Phone");
      }
      else if (user.firstTimeUser) {
        UserAPI.Get2FASetUpCode().then((s) => {
          SetQRCode(s);
        })
        setMode("2FA");
      }
    }
  }, [accessToken]);

  return (
    <>
      {
        mode === "Email" ? <OTPVerifyEmail
          onComplete={() => setMode("Phone")} onClose={() => {
            clearToken();
          }} /> :
          mode === "Phone" ? <OTPVerifyPhone
            onComplete={() => setMode("2FA")} onClose={() => {
              clearToken();
            }} /> :
            <Container maxWidth="sm" className='mt-4 pt-4'>
              <Card elevation={10}>
                <CardHeader
                  avatar={<Avatar>
                    <MobileFriendly />
                  </Avatar>}
                  title={"2FA Setup"} subheader="6 Digit Authenticator Code"
                  action={<Close onClick={() => clearToken()} />}
                />
                <Divider />
                <CardContent>
                  <h5 className="text-center my-2 text-secondary">
                    Scan the QR Code from your authenticator App
                  </h5>
                  <p className="text-center mb-4">
                    <img src={QRCode} alt="QR Code" />
                  </p>
                  <VerifyPin onComplete={() => {
                    Swal.fire({
                      icon: "success",
                      title: "2FA Setup",
                      text: "Please Login again",
                      showConfirmButton: true,
                      confirmButtonText: "Close"
                    }).then((x) => {
                      if (x.isConfirmed) {
                        clearToken();
                      }
                    })

                  }}
                    onClose={() => {
                      clearToken();
                    }}
                  />
                </CardContent>
              </Card>
            </Container>
      }
    </>
  );
}
