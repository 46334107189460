import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { useEffect, useState } from 'react';
import TitleBar from '../base/TitleBar';
import MemberAPI from '../../store/member/member.api';
import { Card, CardHeader, Grid } from '@material-ui/core';
import { AccountBalanceWallet, AccountBalanceWalletTwoTone, ArrowDownward, ArrowUpward, CheckCircle, Description, EmailSharp, MobileFriendly, Money, Person, PlaceRounded, Receipt, VerticalAlignBottom, VerticalAlignTop, WifiTethering } from '@material-ui/icons';
import Loader from '../base/loader';

export default function DashboardContainer() {
    const [isLoading, setLoading] = useState(false);
    const [stats, setStats] = useState({
        totalMembers: 0,
        mobileVerifiedMembers: 0,
        emailVerifiedMembers: 0,
        twoFactorAuthmembers: 0,
        walletWhitelistedMembers: 0,
        tokenApprovedMembers: 0,
        inPlanMembers: 0,
        totalInvestedTokens: 0,
        buyTransactionCount: 0,
        sellTransactionCount: 0,
        totalWithdrawCount: 0,
        totalTokensWithdraw: 0,
        totalCoinsWithdraw: 0,
        totalCoinsBought: 0,
        totalTokensBought: 0,
        totalTokensSold: 0,
        totalCoinsSold: 0,
    });

    useEffect(() => {
        setLoading(true);
        MemberAPI.GetStats().then((d) => {
            setStats(d);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div>
            <TitleBar heading="Dashboard" secondary={""}
                icon={<HelpOutlineIcon />} />
            {
                isLoading && <Loader />
            }
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<Person color="secondary" />}
                            title={stats.totalMembers}
                            subheader="Total Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<MobileFriendly color="secondary" />}
                            title={stats.mobileVerifiedMembers}
                            subheader="Mobile Verified Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<EmailSharp color="secondary" />}
                            title={stats.emailVerifiedMembers}
                            subheader="Email Verified Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<AccountBalanceWallet color="secondary" />}
                            title={stats.twoFactorAuthmembers}
                            subheader="Wallet Created Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<AccountBalanceWalletTwoTone color="secondary" />}
                            title={stats.walletWhitelistedMembers}
                            subheader="Whitelist Wallet Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<CheckCircle color="secondary" />}
                            title={stats.tokenApprovedMembers}
                            subheader="Token Approved Members"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<PlaceRounded color="secondary" />}
                            title={stats.inPlanMembers}
                            subheader="Total Plan Members"
                        />
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<VerticalAlignTop color="secondary" />} title={stats.buyTransactionCount} subheader={"Buy Transactions"} />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<VerticalAlignBottom color="secondary" />} title={stats.sellTransactionCount} subheader={"Sell Transactions"} />
                    </Card>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<ArrowDownward color="secondary" />} title={stats.totalCoinsSold && stats.totalCoinsSold.$numberDecimal + " BNB"} subheader={"Coins Spent"} />
                    </Card>
                </Grid> */}
                {/* <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Card elevation={10}>
                        <CardHeader
                            avatar={<Money color="secondary" />}
                            title={stats.totalInvestedTokens}
                            subheader="Total Invested Amount"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<ArrowUpward color="secondary" />} title={stats.totalTokensSold && stats.totalTokensSold.$numberDecimal + " " + "T369"} subheader={"Tokens Received"} />
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<WifiTethering color="secondary" />} title={stats.totalWithdrawCount} subheader={"Total Withdraws"} />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<Receipt color="secondary" />} title={stats.totalTokensWithdraw + " " + "T369"} subheader={"Tokens Withdrawn"} />
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card elevation={10}>
                        <CardHeader avatar={<Description color="secondary" />} title={stats.totalCoinsWithdraw + " BNB"} subheader={"Coins Withdrawn"} />
                    </Card>
                </Grid> */}
            </Grid>

        </div >
    );
}

