export function getPlans(state) {
    return state.PlanReducer.plans;
}

export function getPlanDetail(state) {
    return state.PlanReducer.plan;
}

export function getPlanMembers(state) {
    return state.PlanReducer.planMembers;
}

export function getMemberPayouts(state) {
    return state.PlanReducer.payouts;
}

export function getMemberWithdraws(state) {
    return state.PlanReducer.withdraws;
}

export function getMemberContracts(state) {
    return state.PlanReducer.contracts;
}

export function getMemberSwaps(state) {
    return state.PlanReducer.swaps;
}