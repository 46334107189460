import { PlanActionTypes } from './plan.model';
require('dotenv').config();

export const initialState = {
    plan: [],
    plans: [],
    planMembers: [],
    payouts: [],
    withdraws: [],
    contracts: [],
    swaps: [],
    isLoading: false,
    walletInfo: {
        coinPrice: 0,
        tokenPrice: {
            symbol: "",
            price: 0,
            name: "",
            decimals: 0,
            address: 0
        },
        tokenBalance: 0,
        coinBalance: 0
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PlanActionTypes.FETCH_PLANS_REQUEST:
        case PlanActionTypes.UPDATE_PLAN_REQUEST:
        case PlanActionTypes.CREATE_PLAN_MEMBER_REQUEST:
        case PlanActionTypes.FETCH_PLAN_MEMBER_REQUEST:
        case PlanActionTypes.FETCH_PLAN_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }

        case PlanActionTypes.FETCH_PLAN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                plan: action.payload
            };
        }

        case PlanActionTypes.FETCH_PLANS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                plans: action.payload
            };
        }

        case PlanActionTypes.FETCH_PLAN_MEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planMembers: action.payload.plans,
                payouts: action.payload.payouts,
                withdraws: action.payload.withdraws,
                contracts: action.payload.contracts,
                walletInfo: action.payload.walletInfo,
                swaps: action.payload.swaps
            };
        }

        case PlanActionTypes.CREATE_PLAN_MEMBER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                planMembers: [...state.planMembers, action.payload]
            };
        }

        case PlanActionTypes.UPDATE_PLAN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                plans: state.plans.map((t) => {
                    if (t._id === action.payload._id) {
                        t = action.payload;
                    }
                    return t;
                })
            };
        }

        case PlanActionTypes.FETCH_PLAN_FAILURE:
        case PlanActionTypes.UPDATE_PLAN_FAILURE:
        case PlanActionTypes.CREATE_PLAN_MEMBER_FAILURE:
        case PlanActionTypes.FETCH_PLAN_MEMBER_FAILURE:
        case PlanActionTypes.FETCH_PLANS_FAILURE: {
            return {
                ...state,
                isLoading: false
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as PlanReducer };