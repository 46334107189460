import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as UserActions from './user.actions';
import UserAPI from './user.api';
import { UserActionTypes } from './user.model';

export function* handleRegisterUser(action) {
  try {
    const response = yield call(
      UserAPI.RegisterUser,
      action.payload
    );
    yield put(UserActions.fetchUsersRequest());
  } catch (e) {
    yield put(UserActions.createUserFailure(e));
  }
}

export function* handleCurrentUser() {
  try {
    const response = yield call(UserAPI.GetCurrentUser);
    yield put(UserActions.fetchUserSuccess(response));
  } catch (e) {
    yield put(UserActions.fetchUserFailure(e));
  }
}

export function* handleUpdateUser(action) {
  try {
    const response = yield call(UserAPI.UpdateCurrentUser, action.payload);
    yield put(UserActions.updateUserSuccess(response));
  } catch (e) {
    yield put(UserActions.updateUserFailure(e));
  }
}

export function* handleUsers() {
  try {
    const response = yield call(UserAPI.GetUsers);
    yield put(UserActions.fetchUsersSuccess(response));
  } catch (e) {
    yield put(UserActions.fetchUsersFailure(e));
  }
}

export function* UserSaga() {
  yield takeLatest(UserActionTypes.REGISTER_USER_REQUEST, handleRegisterUser);
  yield takeEvery(UserActionTypes.FETCH_USER_REQUEST, handleCurrentUser);
  yield takeEvery(UserActionTypes.UPDATE_USER_REQUEST, handleUpdateUser);
  yield takeEvery(UserActionTypes.FETCH_USERS_REQUEST, handleUsers);
}

