import { call, put, takeEvery } from 'redux-saga/effects';
import * as MemberActions from './member.actions';
import MemberAPI from './member.api';
import { MemberActionTypes } from './member.model';
import Swal from 'sweetalert2';

export function* handleRegisterUser(action) {
  try {
    const response = yield call(
      MemberAPI.RegisterMember,
      action.payload
    );
    yield put(MemberActions.registerMemberSuccess(response));
    Swal.fire({
      title: "Registration successful",
      text: "Member ID is " + response.memberId + ".",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });

    yield put(MemberActions.fetchMembersRequest())
  } catch (e) {
    yield put(MemberActions.registerMemberFailure(e));
  }
}

export function* handleFetchMembers(action) {
  try {
    if (action.payload.page) {
      action.payload.page = action.payload.page + 1;
    }
    const response = yield call(MemberAPI.GetMembers, action.payload);
    yield put(MemberActions.fetchMembersSuccess(response));
  } catch (e) {
    yield put(MemberActions.fetchMembersFailure(e));
  }
}

export function* handleUpdateMember(action) {
  try {
    const response = yield call(MemberAPI.UpdateCurrentUser, action.payload);
    yield put(MemberActions.updateMemberSuccess(response));
    Swal.fire({
      title: "Update successful",
      text: "Updated Member ID is " + response.memberId + ".",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(MemberActions.updateMemberFailure(e));
  }
}

export function* handleSuspendMember(action) {
  try {
    const response = yield call(MemberAPI.SuspendUser, action.payload);
    yield put(MemberActions.suspendMemberSuccess(response));
    Swal.fire({
      title: "Suspend User",
      text: "Updated Member ID is " + response.memberId + ".",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(MemberActions.suspendMemberFailure(e));
  }
}

export function* handleApproveToken(action) {
  try {
    const response = yield call(MemberAPI.ApproveContract, action.payload);
    yield put(MemberActions.approveTokenSuccess(response));
    Swal.fire({
      title: "Token Approved",
      text: "Updated Member ID is " + response.memberId + ".",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(MemberActions.approveTokenFailure(e));
  }
}


export function* handleResumeMember(action) {
  try {
    const response = yield call(MemberAPI.ResumeUser, action.payload);
    yield put(MemberActions.resumeMemberSuccess(response));
    Swal.fire({
      title: "Resume Member",
      text: "Updated Member ID is " + response.memberId + ".",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(MemberActions.resumeMemberFailure(e));
  }
}

export function* handleResetMember(action) {
  try {
    const response = yield call(MemberAPI.ResetPassword, action.payload);
    yield put(MemberActions.resetMemberSuccess(response));
    Swal.fire({
      title: "Reset Password",
      text: "Updated Member ID is " + response.memberId + ".",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showConfirmButton: false,
      cancelButtonText: "Close",
      customClass: {
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    });
  } catch (e) {
    yield put(MemberActions.resetMemberFailure(e));
  }
}

export function* handleMemberReferals(action) {
  try {
    const response = yield call(MemberAPI.GetCurrentUserReferals, action.payload);
    yield put(MemberActions.fetchMemberReferalSuccess(response));
  } catch (e) {
    yield put(MemberActions.fetchMemberReferalSuccess(e));
  }
}

export function* handleWithdrawStatusUpdate(action) {
  try {
    yield call(MemberAPI.UpdateWithdrawStatus, action.payload);
    yield put(MemberActions.updateWithdarwStatusSuccess(action.payload));
  } catch (e) {
    yield put(MemberActions.updateWithdarwStatusFailure(e));
  }
}

export function* handleBuyStatusUpdate(action) {
  try {
    yield call(MemberAPI.UpdateBuyStatus, action.payload);
    yield put(MemberActions.updateBuyStatusSuccess(action.payload));
  } catch (e) {
    yield put(MemberActions.updateBuyStatusFailure(e));
  }
}

export function* handleSellStatusUpdate(action) {
  try {
    yield call(MemberAPI.UpdateSellStatus, action.payload);
    yield put(MemberActions.updateSellStatusSuccess(action.payload));
  } catch (e) {
    yield put(MemberActions.updateSellStatusFailure(e));
  }
}

export function* handleStackStatusUpdate(action) {
  try {
    yield call(MemberAPI.UpdateStackStatus, action.payload);
    yield put(MemberActions.updateStackStatusSuccess(action.payload));
  } catch (e) {
    yield put(MemberActions.updateStackStatusFailure(e));
  }
}

export function* handleUnStackStatusUpdate(action) {
  try {
    yield call(MemberAPI.UpdateUnStackStatus, action.payload);
    yield put(MemberActions.updateUnStackStatusSuccess(action.payload));
  } catch (e) {
    yield put(MemberActions.updateUnStackStatusFailure(e));
  }
}

export function* handleMemberReferees(action) {
  try {
    const response = yield call(MemberAPI.GetCurrentUserReferees, action.payload);
    yield put(MemberActions.fetchMemberRefereeSuccess(response));
  } catch (e) {
    yield put(MemberActions.fetchMemberRefereeFailure(e));
  }
}

export function* MemberSaga() {
  yield takeEvery(MemberActionTypes.FETCH_MEMBERS_REQUEST, handleFetchMembers);
  yield takeEvery(MemberActionTypes.UPDATE_MEMBER_REQUEST, handleUpdateMember);
  yield takeEvery(MemberActionTypes.SUSPEND_USER_REQUEST, handleSuspendMember);
  yield takeEvery(MemberActionTypes.APPROVE_TOKEN_REQUEST, handleApproveToken);
  yield takeEvery(MemberActionTypes.RESUME_USER_REQUEST, handleResumeMember);
  yield takeEvery(MemberActionTypes.RESET_PASSWORD_REQUEST, handleResetMember);
  yield takeEvery(MemberActionTypes.FETCH_REFERAL_REQUEST, handleMemberReferals);
  yield takeEvery(MemberActionTypes.FETCH_REFEREE_REQUEST, handleMemberReferees);
  yield takeEvery(MemberActionTypes.REGISTER_MEMBER_REQUEST, handleRegisterUser);
  yield takeEvery(MemberActionTypes.UPDATE_WITHDRAW_STATUS_REQUEST, handleWithdrawStatusUpdate);
  yield takeEvery(MemberActionTypes.UPDATE_BUY_STATUS_REQUEST, handleBuyStatusUpdate);
  yield takeEvery(MemberActionTypes.UPDATE_SELL_STATUS_REQUEST, handleSellStatusUpdate);
  yield takeEvery(MemberActionTypes.UPDATE_STACK_STATUS_REQUEST, handleStackStatusUpdate);
  yield takeEvery(MemberActionTypes.UPDATE_UNSTACK_STATUS_REQUEST, handleUnStackStatusUpdate);
}

