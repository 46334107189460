import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from "../../images/logo.png";
import { Accessibility, AccountBalance, AccountCircle, AccountTree, Assessment, Assignment, Dashboard, ExitToApp, ListAlt, MonetizationOn, PermIdentity, Person, PersonAdd, TransferWithinAStation, VerticalAlignBottom, VerticalAlignTop } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { clearToken, getTokenValue, isAdmin } from '../../helpers/utility';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../store/authentication/auth.selector';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: 9999
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        paddingTop: 0
    },
}));

function ResponsiveDrawer(props) {
    const navigate = useNavigate();
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const { accessToken } = useSelector((state) => {
        return {
            accessToken: getAccessToken(state),
        }
    });

    const user = getTokenValue(accessToken);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Hidden mdDown implementation="css">
                <Typography variant="h6" noWrap className='text-center'>
                    <img src={logo} height={96} className='mt-2' />
                </Typography>
            </Hidden>
            <Hidden smUp implementation="css">
                <div className={classes.toolbar} />
            </Hidden>
            <List disablePadding className='ml-4'>
                <ListItem disableGutters className='py-1' button onClick={() => navigate("/")}>
                    <ListItemIcon>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary={"Dashboard"} />
                </ListItem>
                <ListItem disableGutters className='py-1' button onClick={() => navigate("/profile")}>
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={"Profile"} />
                </ListItem>
                {
                    user.role === "Super Admin" && <ListItem disableGutters className='py-1' button onClick={() => navigate("/users")}>
                        <ListItemIcon>
                            <PersonAdd />
                        </ListItemIcon>
                        <ListItemText primary={"Users"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Member Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/members")}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary={"Members"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Transaction Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/memberTransactions")}>
                        <ListItemIcon>
                            <PermIdentity />
                        </ListItemIcon>
                        <ListItemText primary={"Member Permission"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Member Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/planMembers")}>
                        <ListItemIcon>
                            <ListAlt />
                        </ListItemIcon>
                        <ListItemText primary={"Member Plans"} />
                    </ListItem>
                }
                <ListItem disableGutters className='py-1' button onClick={() => navigate("/plans")}>
                    <ListItemIcon>
                        <ListAlt />
                    </ListItemIcon>
                    <ListItemText primary={"Plan Calculator"} />
                </ListItem>
                {
                    (user.role === "Super Admin" || user.role === "Transaction Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/buy-transactions")}>
                        <ListItemIcon>
                            <VerticalAlignTop />
                        </ListItemIcon>
                        <ListItemText primary={"Buy Transactions"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Transaction Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/sell-transactions")}>
                        <ListItemIcon>
                            <VerticalAlignBottom />
                        </ListItemIcon>
                        <ListItemText primary={"Sell Transactions"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Transaction Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/withdraws")}>
                        <ListItemIcon>
                            <TransferWithinAStation />
                        </ListItemIcon>
                        <ListItemText primary={"Withdraws"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Transaction Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/contracts")}>
                        <ListItemIcon>
                            <Assignment />
                        </ListItemIcon>
                        <ListItemText primary={"Contracts"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Transaction Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/payouts")}>
                        <ListItemIcon>
                            <MonetizationOn />
                        </ListItemIcon>
                        <ListItemText primary={"Payouts"} />
                    </ListItem>
                }
                {
                    (user.role === "Super Admin" || user.role === "Report Admin") &&
                    <ListItem disableGutters className='py-1' button onClick={() => navigate("/reports")}>
                        <ListItemIcon>
                            <Assessment />
                        </ListItemIcon>
                        <ListItemText primary={"Reports"} />
                    </ListItem>
                }
                <ListItem disableGutters className='py-1' button onClick={() => {
                    clearToken();
                }}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItem>
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden smUp implementation="css">
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img src={logo} height={40} />&nbsp;
                        <h3 className='m-0 p-0'>Team 369</h3>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <Hidden smUp implementation="css">
                    <div className={classes.toolbar} />
                </Hidden>
                {props.children}
            </main>
        </div >
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
